<template>
  <div class="select">
    <div
      class="select__selected"
      ref="selectBtn"
      @click="open = !open"
      :class="{ open: open, selectStyle: type === 'select' }"
    >
      <span class="select__title" v-if="selected.title">{{
        selected.title
      }}</span>
      <!-- <span v-if="selected.img"><img :src="selected.img" alt="" /></span> -->
      <span v-for="item in options" :key="item"
      class="langAll"
        >
        <p v-if="item.value == lang">{{ $t('langType') }}</p>
        <p v-if="item.value == lang">|</p>
        <img
          style="width: 21px; height: 14px"
          v-if="item.value == lang"
          :src="item.img"
          alt=""
      /></span>
      <!-- <span v-if="type === 'select' && style === 'gray'" class="select__arrow"
        ><img src="@/assets/icons/arrow.svg" alt=""
      /></span>
      <span v-if="type === 'select' && style === 'black'" class="select__arrow"
        ><img src="@/assets/icons/black-arrow.svg" alt=""
      /></span>
      <span v-if="type === 'lang'" class="select__arrow select__arrow_lang"
        ><img src="@/assets/icons/gray-arrow.svg" alt=""
      /></span> -->
    </div>
    <transition name="fade">
      <div class="select__content" v-if="open" v-click-out-side="hideSelect">
        <ul
          class="select__list"
          :class="{ selectStyle: type === 'select', lang: type === 'lang' }"
        >
          <li
            v-for="(option, idx) in options"
            :key="idx"
            @click="selectOption(option)"
          >
            <span v-if="option.title">{{ option.title }}</span>
            <span v-if="option.img && option.value !== lang" class="langAll1">
              <p v-if="option.value !== lang">{{ option.value }}</p>
              <!-- <p v-if="option.value !== lang">|</p> -->
              <img :src="option.img" alt=""/>
            </span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import { mapGetters } from "vuex";

export default {
  name: "custom-select",
  directives: {
    clickOutSide,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: Object,
      required: false,
    },
    placeholder: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "select",
    },
    clear: {
      type: Boolean,
      required: false,
    },
    style: {
      type: String,
      required: false,
      default: "gray",
    },
  },
  data() {
    return {
      lang: "",
      selected: this.selectedOption
        ? this.selectedOption
        : this.placeholder
        ? this.placeholder
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
  methods: {
    hideSelect(e) {
      const selectBtn = this.$refs.selectBtn;
      if (!(e.target == selectBtn || selectBtn.contains(event.target))) {
        this.open = false;
      }
    },
    selectOption(option) {
      this.selected = option;
      this.open = false;
      this.$emit("select", option);
    },
  },
  mounted() {
    this.lang = this.getLang;
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  watch: {
    clear: function () {
      if (this.clear) this.selected = this.placeholder;
    },
    selectedOption() {
      if (this.selectedOption) {
        this.selected = this.selectedOption;
      } else this.selected = this.placeholder;
    },
  },
};
</script>

<style lang="scss" scoped>
ol,
ul {
  list-style: none;
}

.langAll {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: white;
}
.langAll1 {
  text-transform: uppercase;
  display: flex;
  gap: 14px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: white;
  p {
    width: 18.75px;
  }
}
.select {
  display: inline-block;
  position: relative;
  //z-index: 3;
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #77757f;
  &__list {
    padding: 5px 5px;
    background: #282828;
    border-radius: 4px;
    position: absolute;
    top: 90% !important;
    right: -10%;

    li {
      cursor: pointer;

      &:not(:last-child) {
        margin: 0 0 5px 0;
      }
    }

    &.lang {
      top: calc(100% + 5px);

      img {
        width: 21px;
        height: 14px;
      }
    }

    &.selectStyle {
      top: calc(100% + 10px);
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      left: -1px;
      width: calc(100% + 2px);
      padding: 10px 20px;
    }
  }

  &__selected {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.selectStyle {
      width: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      padding: 18px 20px;
    }

    &.open {
      .select__arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__arrow {
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease 0s;

    img {
      width: 10px;
      height: 10px;
    }

    &_lang {
      margin: 0 0 0 2px;
      width: 20px;
      height: 20px;
    }
  }
}

ol,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}
</style>