<template>
  <div class="header-modal">
    <div class="header-modal__bg" @click="openModal()"></div>
    <CitiesModal
      v-show="isCitiesModal"
      @select-city="selectCity"
      @open-cities-modal="openCitiesModal()"
    >
    </CitiesModal>
    <div class="header-modal__header">
      <div class="header-modal__header-logo" @click="$router.push('/')">
        <img src="../assets/img/main_logo_black.png" alt="" />
      </div>
      <div class="header-modal__header-close" @click="openModal()">
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="desctop1 desctop w-100">
      <div class="header-modal__wrapper">
        <div class="header-modal__products">
          <div
            class="header-modal__products-item"
            v-for="item in allCategories"
            :key="item.id"
            @click="(additionaly = item), (activeCategory = item.id)"
            :class="{
              active_category: activeCategory === item.id,
              asd: activeCategory === item.id,
            }"
          >
            <div class="header-modal__products-icon">
              <img v-if="item.icon" :src="$cdn + item.icon" />
            </div>
            <div class="header-modal__products-title">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="header-modal__additionaly">
          <div class="header-modal__additionaly-title">
            {{ additionaly.title }}
          </div>
          <div class="header-modal__additionaly-items">
            <div
              class="header-modal__additionaly-item"
              v-for="item in additionaly.sub_categories"
              :key="item.id"
            >
              <router-link
                :to="'/catalog/' + additionaly.slug + '/' + item.slug"
              >
                {{ item.title }}</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <img
        class="desctop-close1"
        @click="openModal()"
        :src="require('@/assets/icons/home/x.png')"
      />
    </div>
    <div class="mobile">
      <div class="header-modal__mobile">
        <div class="header-modal__products" v-if="!setCategory">
          <div class="header-modal__activity">
            <div class="header__city" @click="isCitiesModal = true">
              <div class="header__icon">
                <img
                  src="../assets/icons/location_black.png"
                  alt="Location Icon"
                />
              </div>
              <div class="header__name">
                {{ city }}
              </div>
            </div>

            <div class="header__phone">
              <div class="header__icon">
                <img src="../assets/icons/phone_black.png" alt="Phone Icon" />
              </div>
              <div class="header__number">+7 (777) 777 77 - 77</div>
            </div>
          </div>
          <ul class="header__list">
            <li
              class="header__item"
              v-for="item in list"
              :key="item.id"
              @click="$router.push('/' + item.link)"
            >
              {{ item.text }}
            </li>
          </ul>
        </div>
        <div class="header-modal__category" v-else>
          <div class="header-modal__category-title" @click="closeCategory">
            <img :src="require('@/assets/icons/home/left.png')" />
            {{ activityCategory.title }}
          </div>
          <div class="header-modal__category-items">
            <div
              @click="routeTo(item.id)"
              v-for="item in activityCategory.sub_categories"
              :key="item.id"
            >
              <router-link
                :to="'/catalog/' + activityCategory.slug + '/' + item.slug"
              >
                {{ item.title }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CitiesModal from "./Cities.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      city: "Атырауская область",
      isCitiesModal: false,
      additionaly: [],
      activeCategory: null,
      activityCategory: null,
      setCategory: false,
      modal: false,
      list: [
        {
          id: 0,
          text: this.$t("shop"),
          link: "market-shop",
        },
        {
          id: 1,
          text: this.$t("delivery"),
          link: "delivery",
        },
        {
          id: 2,
          text: this.$t("payment"),
          link: "payment",
        },
        {
          id: 3,
          text: this.$t("cooperation"),
          link: "cooperation",
        },
        {
          id: 4,
          text: this.$t("news"),
          link: "news",
        },
      ],
    };
  },
  async mounted() {
    this.city = localStorage.getItem("city") || "Алматы";
    // await this.GET_CATEGORY(this.additionaly);
  },
  methods: {
    selectCity(my_city) {
      this.city = my_city;
      this.isCitiesModal = false;
    },
    openCitiesModal() {
      this.isCitiesModal = false;
    },
    openMenu(item) {
      this.activityCategory = item;
      this.setCategory = true;
    },
    closeCategory() {
      this.setCategory = false;
    },
    routeToHome() {
      this.$router.push("/");
    },
    openModal() {
      this.$emit("openModal");
      this.setCategory = false;
    },
  },
  computed: {
    ...mapGetters(["allCategories"]),
  },
  watch: {
    allCategories() {
      this.activeCategory = this.allCategories[0].id;
      this.additionaly = this.allCategories[0];
    },
    $route() {
      this.openModal();
    },
  },
  components: {
    CitiesModal,
  },
};
</script>

<style lang="scss" scoped>
ol,
ul {
  list-style: none;
}

a {
  color: #202020;
  font-weight: 400;
  font-size: 16px;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}
.w-100 {
  width: 100%;
}
.mobile {
  width: 100%;
}
.asd {
  width: 100%;
  outline: 10px solid #f2f2f2;
}
.active_category {
  background: #f2f2f2;
}
.header {
  &-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s;

    &__header {
      display: none;
    }

    &-top {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__mobile {
      width: 100vw;
    }

    &__bg {
      position: fixed;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.36);
      z-index: -1;
      display: flex;

      @media (max-width: 991px) {
        display: none;
      }
    }

    &__wrapper {
      padding: 41px 36px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 67vh;
      background: #ffffff;
      gap: 0 60px;

      @media (max-width: 1600px) {
        margin-top: 120px;
        height: 75vh;
        padding: 41px 36px;
      }

      @media (max-width: 1200px) {
        padding: 41px 36px;
      }
    }

    &__products {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      max-width: 400px;
      gap: 40px 0;
      margin-top: 18px;
      @media (max-width: 1600px) {
        gap: 30px;
        max-width: 400px;
      }

      @media (max-width: 1400px) {
        gap: 20px;
        max-width: 350px;
      }


      @media (max-width: 992px) {
        gap: 20px;
        width: 100%;
        max-width: 550px;
      }
      &-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0 12px;

        &:hover {
          cursor: pointer;
        }
      }

      &-icon {
        width: 21px;
        min-width: 21px;
      }

      &-title {
        font-weight: 400;
        font-size: 18px;
        color: #202020;
      }
    }

    &__additionaly {
      max-width: 788px;
      flex-grow: 0.8;
      @media (max-width: 1200px) {
      }

      &-title {
        max-width: 788px;
        min-width: 100%;
        width: 100%;
        height: 45px;
        background: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        color: #202020;

        @media (max-width: 1200px) {
          width: 100%;
        }
      }

      &-items {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px 0px;
        flex-wrap: wrap;
        height: 410px;

        @media (max-width: 1200px) {
          height: 320px;
          margin-top: 10px;
          gap: 12px 40px;
          overflow: auto;
        }

        @media (max-width: 1400px) {
          gap: 12px 40px;
        }
      }

      &-item {
        font-weight: 400;
        font-size: 16px;
        color: #202020;

        @media (max-width: 1400px) {
          gap: 12px 40px;
          max-width: 260px;
        }
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .header {
    &-modal {
      padding: 50px;
      position: fixed;
      width: 100%;
      height: 100%;
      overflow: auto;
      top: 0;
      left: 0;
      z-index: 99999999;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      background-color: #fff;
      flex-direction: column;
      overflow: auto;

      @media (max-width: 768px) {
        padding: 16px;
        padding-top: 2px;
      }

      &__bg {
        display: none;
      }

      &__header {
        position: relative;
        display: flex;
        min-height: 57px;
        width: 100%;
        align-items: center;

        &-logo {
          width: 41.67px;
        }

        &-close {
          transform: rotate(-45deg);
          position: absolute;
          top: 33%;
          right: 20px;

          span {
            position: absolute;
            right: 0;
            height: 20px;
            width: 2px;
            background-color: #202020;

            &:nth-child(2) {
              transform: rotate(90deg);
            }
          }
        }
      }

      &__mobile {
        margin-top: 20px;
        width: 100%;
      }

      &__category {
        &-title {
          display: flex;
          align-items: center;
          gap: 20px;
          height: 18px;
          img {
            width: 8px;
          }
        }
        &-items {
          margin-top: 32px;
          display: flex;
          flex-direction: column;
          gap: 32px;
        }
      }

      &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        z-index: 99999999;

        &-close {
          span {
            height: 1px;
            width: 25px;
            background-color: black;
          }
        }
      }

      &__wrapper {
        padding: 16px 32px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: auto;
        background: #ffffff;
        gap: 0 32px;
      }

      &__products {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 40px 0;
        height: 100%;
        margin-top: 0px;
        overflow: auto;

        &-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0 21px;
          width: 98%;
        }

        &-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &:hover {
            cursor: pointer;
          }
        }

        &-icon {
          width: 18px;
          height: 18px;
          min-width: 18px;
        }

        &-rigth {
          width: 8px;
        }

        &-title {
          font-weight: 400;
          font-size: 16px;
          flex-grow: 1;
          color: #202020;
        }

        &-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          width: 100%;
        }
      }

      &__activity {
        width: 100%;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          top: -10px;
          left: 0px;
          background-color: #e3e5e6;
        }
      }

      &__additionaly {
        &-items {
          margin-top: 36px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 36px;
          flex-wrap: wrap;
          height: auto;
        }

        &-item {
        }
      }
    }

    &__list {
      flex-direction: column;
      align-items: flex-start;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      padding-top: 6px;
      margin-top: 0 !important;
      width: 100%;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        top: -20px;
        left: 0px;
        background-color: #e3e5e6;
      }
    }

    &__item {
      &:first-child {
        margin-top: 0px;
      }
      margin-top: 16px;
      color: #202020;
    }

    &__city,
    &__phone {
      display: flex;
      align-items: center;
      margin-top: 16px;
    }

    &__name,
    &__number {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #202020;
    }
  }
}

ol,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}
</style>
