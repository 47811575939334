<template>
    <div class="acc-main">
        <div class="acc-main__wrapper">
            <!-- <div class="acc__title" @click="$router.go(-1)" :class="getType === 'individual' ? 'indTitle' : ''"> -->
            <div class="acc__title" @click="$router.go(-1)" :class="getType === 'physical' ? 'indTitle' : ''">
      <img :src="require('@/assets/icons/leftArrow.png')" alt="left" />{{ $t("mainPage") }}</div>
            <div class="acc-main__noted">
                <div class="acc-main__title">Уведомления</div>
                <div class="acc-main__items">
                    <div class="acc-main__item" v-for="item in notes" :key="item.id">
                        <div class="acc-main__item-info">
                            <div class="acc-main__time">{{ item.date }}, {{ item.time }}</div>
                            <div class="acc-main__info">
                                Заказ №{{ item.number }} от {{ item.start_time }} готов к оплате, документы для оплаты прикреплены к заказу и также высланы на Ваш e-mail. Крайний срок оплаты заказа - {{ item.last_time }}
                            </div>
                        </div>
                        <div class="acc-main__icon">
                            <img src="../../assets/icons/basket/delete.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="acc-main__blocks">
                    <div class="acc-main__block">
                        Заказов за {{ receivedData }}
                        <div class="acc-main__count">
                            {{ staticData.orders_month }}
                        </div>
                        <div class="acc-main__total">{{ Object.keys(this.staticData).length ? this.staticData.price_month.toLocaleString() : '0' }} ₸</div>
                    </div>

                    <div class="acc-main__block">
                        Заказов на 2023 год
                        <div class="acc-main__count">
                            {{ staticData.orders_year }}
                        </div>
                        <div class="acc-main__total">{{ Object.keys(this.staticData).length ? this.staticData.price_year.toLocaleString() : '0' }} ₸</div>
                    </div>

                    <div class="acc-main__block">
                        Средний заказ за {{ receivedData }}
                        <div class="acc-main__count">
                            {{ staticData.average_orders_month }}
                        </div>
                        <div class="acc-main__total">{{ Object.keys(this.staticData).length ? this.staticData.average_price_month.toLocaleString() : '0' }} ₸</div>
                    </div>

                    <div class="acc-main__block">
                        Всего заказов
                        <div class="acc-main__count">
                            {{ staticData.total_orders }}
                        </div>
                        <div class="acc-main__total">{{ Object.keys(this.staticData).length ? this.staticData.total_price.toLocaleString() : '0' }} ₸</div>
                    </div>

                </div>

                <div class="acc-main__graph monthStat">
                    <p class="monthStat-p1">{{ staticData.total_orders }} заказов на 2023 год</p>
                    <p class="monthStat-p2">{{ formattedDate }}</p>
                    <LineChart :static-data="staticData" @static-data-updated="onStaticDataUpdated" @month-data-updated="handleData"></LineChart>
                    <!-- <div class="monthStat__mon">
                        <p>Январь</p>
                        <p>Февраль</p>
                        <p>Март</p>
                        <p>Апрель</p>
                        <p>Май</p>
                        <p>Июнь</p>
                        <p>Июль</p>
                        <p>Август</p>
                        <p>Сентябрь</p>
                        <p>Октябрь</p>
                        <p>Ноябрь</p>
                        <p>Декабрь</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from "../../components/LineChart.vue";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
    data() {
        return {
            currentDate: new Date(),
            staticData: [],
            receivedData: '',
            monthNames: [
                "Январь", "Февраль", "Март", "Апрель",
                "Май", "Июнь", "Июль", "Август",
                "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
            ],
            notes: [{
                    id: 0,
                    date: "22.05.2022",
                    time: "16:30",
                    number: "36541",
                    start_time: "15.05.2022",
                    last_time: "29.05.2022",
                },
                {
                    id: 1,
                    date: "22.05.2022",
                    time: "15:30",
                    number: "355541",
                    start_time: "16.05.2022",
                    last_time: "30.05.2022",
                },
            ],
        };
    },
    created() {
        this.$store.dispatch("checkAuth");
    },
    methods: {
        onStaticDataUpdated(newStaticData) {
            this.staticData = newStaticData
        },
        handleData(value) {
            this.receivedData = value;
        }
    },
    computed: {
        ...mapGetters(["getType"]),

        formattedDate() {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };
        const date = this.currentDate.toLocaleDateString('ru-RU', options);
        return date.charAt(0).toUpperCase() + date.slice(1);
        },
        
    },
    async mounted() {
      await this.$axios.get('V1/current-statistic', {
        headers: {
            Authorization: "Bearer ".concat(
                localStorage.getItem("access_token")
            ),
        },
        })
        .then(res => {
            this.staticData = res.data.statistics
        })
        // .catch(err => console.log(err))
        const date = new Date();
        const monthIndex = date.getMonth();
        const monthName = this.monthNames[monthIndex];
        const monthNumber = monthIndex + 1;
        this.receivedData = monthName;
    },
    components: {
        LineChart,
    },
};
</script>

<style lang="scss">
.monthStat {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    padding: 32px;
    &-p1 {
        font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.3125))), 20px);
        color: #202020;
        font-weight: 600;
        margin-bottom: 8px;
    }
    &-p2 {
        font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.125))), 14px);
        color: #727272;
         margin-bottom: 70px;
    }
    &__mon {
        color: #727272;
        margin-top: 10px;
        margin-left: 35px;
        font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.125))), 14px);
        display: flex;
        justify-content: space-between;
    }
}
</style>