<template>
  <modal-become-partner />
  <div class="footer">
    <div v-if="contacts">
      <div class="footer__wrapper desctop">
        <div class="footer__content content">
          <div class="footer__media">
            <router-link to="/" class="footer__logo">
              <img src="../assets/img/main_logo_white.png" alt="Main logo" />
            </router-link>
            <div class="footer__social">
              <a :href="`${contacts.instagram}`" class="footer__social-item">
                <img src="../assets/icons/insta_black.png" alt="" />
              </a>
              <a :href="`${contacts.vk}`" class="footer__social-item">
                <img src="../assets/icons/vk_black.png" alt="" />
              </a>
              <a :href="`${contacts.facebook}`" class="footer__social-item">
                <img src="../assets/icons/fb_black.png" alt="" />
              </a>
            </div>
          </div>

          <ul class="footer__items" style="max-width: 300px">
            <router-link :to="'/catalog/'+ item.slug"
              class="footer__item"
              v-for="item in allCategories"
              :key="item.id"
            >
              {{ item.title }}
            </router-link>
          </ul>
          <ul class="footer__items">
            <router-link :to="'/'+ item.link"
              class="footer__item"
              v-for="item in list"
              :key="item.id"
            >
              {{ item.text }}
            </router-link>
          </ul>
          <ul class="footer__items">
            <template v-for="item in listFaq" :key="item.id">
              <template v-if="item.id === 6">
                <a
                  style="white-space: nowrap"
                  class="footer__item"
                  target="_blank"
                  :href="item.link"
                  v-if="item.link"
                >
                  {{ item.text }}
                </a>
              </template>
              <template v-else>
                <router-link
                  style="white-space: nowrap"
                  class="footer__item"
                  :to="'/' + item.link"
                  v-if="item.link"
                >
                  {{ item.text }}
                </router-link>
                <div v-else class="footer__item" @click="$store.state.openPartnersModal = true">
                  {{ item.text }}
                </div>
              </template>
            </template>
          </ul>
          <ul class="footer__items">
            <router-link to="/contact" class="footer__item">
              {{ $t("contacts") }}
            </router-link>
            <li class="footer__contact">
              <div class="footer__icon">
                <img src="../assets/icons/location_white.png" alt="" />
              </div>
              <p v-html="contacts.main_address" style="width: 230px" />
            </li>
            <li class="footer__contact">
              <div class="footer__icon">
                <img src="../assets/icons/phone_white.png" alt="" />
              </div>
              
              <div style="display: flex; flex-direction: column; gap: 8px;">
                <p>{{ $t('Розничные продажи') }}</p>
                <a :href="'tel:' + contacts.retail_sale" style="color: white;">{{ contacts.retail_sale }}</a>
                <p>{{ $t('Оптовые продажи') }}</p>
                <a :href="'tel:' + contacts.wholesale " style="color: white;">{{ contacts.wholesale }}</a>
              </div>

            </li>
            <li class="footer__contact">
              <div class="footer__icon">
                <img src="../assets/icons/mail_white.png" alt="" />
              </div>
              <p v-html="contacts.email" />
            </li>
          </ul>
        </div>
        <div style="height: 1px; background: #FFFFFF33; width: 100%; margin: 27px 0 9px;"></div>
        <div class="footer__rules content" style="display: flex; justify-content: space-between;">
          © 2022 TOO GES. {{ $t("allRightsReserved") }}
          <router-link to="/personal_data" style="color: #FFFFFF;">{{ $t('privacyPolicy') }}</router-link>
          <router-link to="/terms" style="color: #FFFFFF;">{{ $t('legalInformation') }}</router-link>
          <p>{{ $t('StudioNomad') }}</p>
        </div>
      </div>

      <!-- <div class="footer-menu mobile">
        <div class="footer-menu__titles">
          <template v-for="title in titles" :key="title.id">
            <div class="footer-menu__title" @click="title.open = !title.open">
              <div class="footer-menu__text" v-html="title.text"></div>
              <div class="footer-menu__icon">
                <img
                  src="../assets/icons/mobile/arrow.svg"
                  alt="Icon"
                  v-bind:class="{ open: title.open }"
                />
              </div>
            </div>
            <div
              class="footer-menu__items"
              v-for="item in title.menu_items"
              :key="item.id"
              v-show="title.open"
            >
              <div class="footer-menu__item" @click="routeTo(item.link)">
                <span>{{ item.text }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="footer__social">
          <a :href="`${contacts.instagram}`" class="footer__social-item">
            <img src="../assets/icons/insta_white.png" alt="" />
          </a>
          <a :href="`${contacts.vk}`" class="footer__social-item">
            <img src="../assets/icons/vk_white.png" alt="" />
          </a>
          <a :href="`${contacts.facebook}`" class="footer__social-item">
            <img src="../assets/icons/fb_white.png" alt="" />
          </a>
        </div>

        <router-link to="/personal_data" style="color: #FFFFFF;" class="privacyLegal"><p>{{ $t('privacyPolicy') }}</p></router-link>
        <router-link to="/terms" style="color: #FFFFFF;" class="privacyLegal"><p>{{ $t('legalInformation') }}</p></router-link>
        <div class="footer__rules">
          © 2022 TOO GES. {{ $t("allRightsReserved") }}
        </div>
      </div> -->

      <div class="mobile" style="width: 95%; margin: auto;">
          <div class="footerMobile">
              <div class="footerMobile__box">
                  <div class="footerMobile__box__top">
                    <p>Каталог</p>
                    <img src="../assets/icons/mobile/arrow.svg" alt="Icon" @click="openFooterMobile(1)" :style="valueMob == 1 ? 'transform: rotate(90deg)' : ''" class="ser"/>
                  </div>
                  <div class="footerMobile__box-line"></div>
                  <div class="footerMobile__box__links" v-if="valueMob == 1">
                      <router-link :to="'/catalog/'+ item.slug"
                        v-for="item in allCategories"
                        :key="item.id"
                      >
                        {{ item.title }}
                      </router-link>
                  </div>
              </div>
              
              <div class="footerMobile__box">
                  <div class="footerMobile__box__top">
                    <p>О компании</p>
                    <img src="../assets/icons/mobile/arrow.svg" alt="Icon" @click="openFooterMobile(2)" :style="valueMob == 2 ? 'transform: rotate(90deg)' : ''" class="ser"/>
                  </div>
                  <div class="footerMobile__box-line"></div>
                  <div class="footerMobile__box__links" v-if="valueMob == 2">
                      <router-link :to="'/'+ item.link"
                        v-for="item in AboutComMobile"
                        :key="item.id"
                      >
                        {{ item.text }}
                      </router-link>
                  </div>
              </div>

              <div class="footerMobile__box">
                  <div class="footerMobile__box__top">
                    <p>Клиентам</p>
                    <img src="../assets/icons/mobile/arrow.svg" alt="Icon" @click="openFooterMobile(3)" :style="valueMob == 3 ? 'transform: rotate(90deg)' : ''" class="ser"/>
                  </div>
                  <div class="footerMobile__box-line"></div>
                  <div class="footerMobile__box__links" v-if="valueMob == 3">
                      <router-link :to="'/'+ item.link"
                        v-for="item in ForClientMobile"
                        :key="item.id"
                      >
                        {{ item.text }}
                      </router-link>
                  </div>
              </div>

              <div class="footerMobile__box">
                  <div class="footerMobile__box__top">
                    <p>Партнерам</p>
                    <img src="../assets/icons/mobile/arrow.svg" alt="Icon" @click="openFooterMobile(4)" :style="valueMob == 4 ? 'transform: rotate(90deg)' : ''" class="ser"/>
                  </div>
                  <div class="footerMobile__box-line"></div>
                  <div class="footerMobile__box__links" v-if="valueMob == 4">
                      <p @click="$router.push('/market-shop')">{{ this.$t("shop") }}</p>
                      <p @click="$store.state.openPartnersModal = true">{{ this.$t("Стать партнером") }}</p>
                      <a href="https://admin-ges.test-vip.kz/shop-login">{{ this.$t("Кабинет продавца") }}</a>
                  </div>
              </div>

              <div class="footerMobile__box">
                  <div class="footerMobile__box__top">
                    <p>Партнерам</p>
                    <img src="../assets/icons/mobile/arrow.svg" alt="Icon" @click="openFooterMobile(5)" :style="valueMob == 5 ? 'transform: rotate(90deg)' : ''" class="ser"/>
                  </div>
                  <div class="footerMobile__box-line"></div>
                  <div class="footerMobile__box__links" v-if="valueMob == 5">
                      <ul class="footer__items">
                          <li class="footer__contact">
                            <div class="footer__icon">
                              <img src="../assets/icons/location_white.png" alt="" />
                            </div>
                            <p v-html="contacts.main_address" style="width: 230px" />
                          </li>
                          <li class="footer__contact">
                            <div class="footer__icon">
                              <img src="../assets/icons/phone_white.png" alt="" />
                            </div>
                            
                            <div style="display: flex; flex-direction: column; gap: 8px;">
                              <p>{{ $t('Розничные продажи') }}</p>
                              <a :href="'tel:' + contacts.retail_sale" style="color: white;">{{ contacts.retail_sale }}</a>
                              <p>{{ $t('Оптовые продажи') }}</p>
                              <a :href="'tel:' + contacts.wholesale " style="color: white;">{{ contacts.wholesale }}</a>
                            </div>

                          </li>
                          <li class="footer__contact">
                            <div class="footer__icon">
                              <img src="../assets/icons/mail_white.png" alt="" />
                            </div>
                            <p v-html="contacts.email" />
                          </li>
                    </ul>
                  </div>
              </div>

              <div class="footer__social">
                <a :href="`${contacts.instagram}`" class="footer__social-item">
                  <img src="../assets/icons/insta_white.png" alt="" />
                </a>
                <a :href="`${contacts.vk}`" class="footer__social-item">
                  <img src="../assets/icons/vk_white.png" alt="" />
                </a>
                <a :href="`${contacts.facebook}`" class="footer__social-item">
                  <img src="../assets/icons/fb_white.png" alt="" />
                </a>
            </div>

            <router-link to="/personal_data" style="color: #FFFFFF;" class="privacyLegal"><p>{{ $t('privacyPolicy') }}</p></router-link>
            <router-link to="/terms" style="color: #FFFFFF;" class="privacyLegal"><p>{{ $t('legalInformation') }}</p></router-link>
            <div class="footer__rules">
              © 2022 TOO GES. {{ $t("allRightsReserved") }}
            </div>

          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalBecomePartner from "./ModalBecomePartner.vue";
export default {
  data() {
    return {
      valueMob: 0,
      item_count: null,
      mobile_menu_open: 0,
      isOpen: false,
      contacts: {},
      ForClientMobile: [
        {
          text: this.$t("shop"),
          link: "market-shop"
        },
        {
          text: this.$t("sale"),
          link: "sales"
        },
        {
          text: this.$t("delivery"),
          link: "delivery"
        },
        {
          text: this.$t("payment"),
          link: "payment"
        }
      ],
      AboutComMobile: [
        {
          text: this.$t("aboutUs"),
          link: "about"
        },
        {
          text: this.$t("news"),
          link: "news"
        },
        {
          text: this.$t("Ges Гид"),
          link: "faq"
        }
      ],
      listFaq: [
        {
          id: 0,
          text: this.$t("cooperation"),
          link: "cooperation",
        },
        {
          id: 1,
          text: this.$t("partners"),
          link: false,
        },
        {
          id: 6,
          text: this.$t("Кабинет продавца"),
          link: "https://admin-ges.test-vip.kz/shop-login"
        },
        {
          id: 2,
          text: this.$t("Ges Гид"),
          link: "faq",
        },
        {
          id: 5,
          text: this.$t("news"),
          link: "news",
        },
        {
          id: 3,
          text: this.$t("contacts"),
          link: "contact",
        },
        // {
        //   id: 3,
        //   text: this.$t("legalInformation"),
        //   link: "/",
        // },
        // {
        //   id: 4,
        //   text: this.$t("privacyPolicy"),
        //   link: "/",
        // },
      ],
      list: [
        {
          id: 0,
          text: this.$t("shop"),
          link: "market-shop",
        },
        {
          id: 1,
          text: this.$t("sale"),
          link: "sales",
        },
        {
          id: 1,
          text: this.$t("delivery"),
          link: "delivery",
        },
        {
          id: 2,
          text: this.$t("payment"),
          link: "payment",
        },
        {
          id: 3,
          text: this.$t("aboutUs"),
          link: "about",
        },
        // {
        //   id: 4,
        //   text: this.$t("cooperation"),
        //   link: "",
        // },
      ],
      goods: [
        {
          id: 0,
          text: "Строительные материалы ",
          link: "",
        },
        {
          id: 1,
          text: "Электроинструменты ",
          link: "",
        },
        {
          id: 2,
          text: "Ручные инструменты ",
          link: "",
        },
        {
          id: 3,
          text: "Расходные материалы ",
          link: "",
        },
        {
          id: 4,
          text: "Автозапчасти ",
          link: "",
        },
        {
          id: 5,
          text: "Бытовая электроника ",
          link: "",
        },
        {
          id: 5,
          text: "Спецодежда",
          link: "",
        },
        {
          id: 5,
          text: "Электротовары",
          link: "",
        },
        {
          id: 5,
          text: "Лакокрасочные материалы",
          link: "",
        },
      ],
      help: [
        {
          id: 0,
          text: this.$t("partners"),
          link: "cooperation",
        },
        {
          id: 1,
          text: this.$t("cooperation"),
          link: "cooperation",
        },
        {
          id: 2,
          text: this.$t("askedQuestions"),
          link: "FAQ",
        },
        {
          id: 3,
          text: this.$t("legalInformation"),
          link: "personal-data",
        },
        {
          id: 4,
          text: this.$t("privacyPolicy"),
          link: "confidential",
        },
      ],
      titles: [
        {
          id: 0,
          text: this.$t("catalog"),
          open: false,
          menu_items: [
            {
              id: 0,
              text: this.$t("shop"),
              link: "",
            },
            {
              id: 1,
              text: this.$t("sale"),
              link: "",
            },
            {
              id: 2,
              text: this.$t("delivery"),
              link: "",
            },
            {
              id: 3,
              text: this.$t("payment"),
              link: "",
            },
            {
              id: 4,
              text: this.$t("aboutUs"),
              link: "",
            },
            {
              id: 5,
              text: this.$t("news"),
              link: "",
            },
            {
              id: 0,
              text: this.$t("contacts"),
              link: "/contact",
            },
          ],
        },
        {
          id: 1,
          text: "О компания",
          open: false,
          menu_items: [
            {
              id: 0,
              text: this.$t("aboutUs"),
              link: "/about",
            },
            {
              id: 1,
              text: this.$t("shop"),
              link: "",
            },
            {
              id: 2,
              text: this.$t("shop"),
              link: "",
            },
            {
              id: 3,
              text: this.$t("shop"),
              link: "",
            },
            {
              id: 4,
              text: "О Магазины",
              link: "",
            },
            {
              id: 5,
              text: this.$t("shop"),
              link: "",
            },
            {
              id: 0,
              text: this.$t("contacts"),
              link: "",
            },
          ],
        },
        {
          id: 2,
          text: "Клиентам",
          open: false,
          menu_items: [
            {
              id: 0,
              text: "Аккаунт",
              link: "/acc/profile",
            },
            {
              id: 1,
              text: this.$t("myFavourites"),
              link: "/acc/liked",
            },
            {
              id: 2,
              text: this.$t("myProfile"),
              link: "/acc/profile",
            },
            {
              id: 3,
              text: "Мои заказы",
              link: "/acc/orders",
            },
            {
              id: 4,
              text: this.$t("mySales"),
              link: "/acc/sales",
            },
            {
              id: 5,
              text: "Комменты",
              link: "/acc/comments",
            },
            {
              id: 6,
              text: "Мессенджер",
              link: "/acc/messanger",
            },
          ],
        },
        {
          id: 3,
          text: "Партнерам",
          open: false,
          menu_items: [
            {
              id: 0,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 1,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 2,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 3,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 4,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 5,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 0,
              text: this.$t("contacts"),
              link: "",
            },
          ],
        },
        {
          id: 4,
          text: this.$t("contacts"),
          open: false,
          menu_items: [
            {
              id: 0,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 1,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 2,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 3,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 4,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 5,
              text: this.$t("contacts"),
              link: "",
            },
            {
              id: 0,
              text: this.$t("contacts"),
              link: "",
            },
          ],
        },
      ],
      items: [
        {
          id: 0,
          text: this.$t("shop"),
          link: "",
        },
        {
          id: 1,
          text: this.$t("sale"),
          link: "",
        },
        {
          id: 2,
          text: this.$t("delivery"),
          link: "",
        },
        {
          id: 3,
          text: this.$t("payment"),
          link: "",
        },
        {
          id: 4,
          text: this.$t("aboutUs"),
          link: "about",
        },
        {
          id: 5,
          text: this.$t("news"),
          link: "news",
        },
        {
          id: 0,
          text: this.$t("contacts"),
          link: "contact",
        },
      ],
    };
  },
  async mounted() {
    await this.$axios
      .get(`get-contacts`)
      .then((response) => {
        this.contacts = response.data.data;
      })
      .catch((e) => {
        // console.log(e);
      });
  },
  computed: {
    ...mapGetters(["allCategories", "getIsAuth"]),
  },
  methods: {
    openFooterMobile(value) {
      if (this.valueMob >= 0 && this.valueMob == value) {
        this.valueMob = -(this.valueMob = value)
      }
      else {
        this.valueMob = (this.valueMob = value)
      }
    },
    onMenu() {
      this.isOpen = !this.isOpen;
      // console.log("its work");
    },
    routeTo(link) {
      this.$router.push(`/catalog/${link}`);
      // console.log("123123");
    },
  },
  components: { ModalBecomePartner },
};
</script>


<style lang="scss" scoped>
.footerMobile {
  padding: 26px 0;
  background: #F2F2F2;
  color: black;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__box {
    img {
      width: auto;
    }
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        rotate: -90deg;
      }
    }
    &__links {
      width: 95%;
      padding-bottom: 16px;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 11px;
      color: #202020B2 !important;
      a {
        color: #202020B2 !important;
      }
      p {
        color: #202020B2 !important;
      }
      li {
        color: #202020B2 !important;
      }
    }
    &-line {
      margin: 12px 0 14px 0;
      background: #E3E5E6;
      width: 100%;
      height: 1px;
    }
  }
}

ol,
ul {
  list-style: none;
}

ol,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: flex-start;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

.footer__items {
  div {
    color: #FFFFFF;
  }
  a {
    color: #FFFFFF;
  }
  p {
    color: #FFFFFF;
  }
}
.footer__rules {
    font-size: min(max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.25))), 14px);
  a {
    font-size: min(max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.25))), 14px);
  }
  p {
    font-size: min(max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.25))), 14px);
  }
  
  @media (max-width: 991px) {
    font-size: 13px !important;
    margin: 8px 0 0px 0;
  }
}

.footer-menu {
  @media (max-width: 991px) {
    padding: 26px 15px;
  }
}
.footer {
  @media (max-width: 991px) {
    padding: 0 0 54px 0;
    background: #F2F2F2 !important;
  }

}
.privacyLegal {
  color: black !important;
  font-size: 12px !important;
  p {
    font-size: 12px !important;
    font-weight: 400 !important;
    padding: 13px 0 !important;
  }
}
.footer__items {
  @media (max-width: 991px) {
    p {
      color: #202020B2 !important;
      font-weight: 400 !important;
      font-size: 11px !important;
    }
    a {
      color: #202020B2 !important;
      font-weight: 400 !important;
      font-size: 11px !important;
    }
    font-weight: 400 !important;
    font-size: 11px !important;
    filter: brightness(0) saturate(100%) invert(10%) sepia(0%) saturate(2710%) hue-rotate(86deg) brightness(90%) contrast(92%);
  }
}
.footer__icon {
  @media (max-width: 991px) {
    img {
      width: 11px !important;
      height: 11px !important;
    }
  }
}
.footer__social {
  @media (max-width: 991px) {
    margin-top: 15px !important;
  }
}
.ser {
   @media (max-width: 991px) {
    width: 3% !important;
   }
}
</style>