<template>
  <div class="faq">
    <div class="content">
      <!-- <BreadCrumbs :items="[[$t('askedQuestions'), '/about']]" /> -->

      <div class="bread"> 
          <router-link to="/" style="color: black"><p>{{ $t('main') }}</p></router-link>
         <div style="opacity: 0.3;">•</div>
         <p style="opacity: 0.7;">{{ $t('askedQuestions') }}</p>
      </div>

      <div class="faq__main">
        <div class="faq__menu menuGap">
          <div
            v-for="item in faq" :key=item.id
            class="faq__menu-item menuGap-item"
            @click="isActiveMenu = item.id, $router.push('/faq/' + item.slug);"
            :class="{ active_menu: isActiveMenu == item.id }"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="faq__content">
          <div class="faq__title">{{ faq[isActiveMenu - 1]?.title }}</div>
          <div class="faq__items">
            <div
              class="faq__item"
              v-for="item in faq[isActiveMenu - 1]?.sub_faq"
              :key="item.id">
              <div
                class="faq__item-title"
                style="cursor: default;"
                :class="{ active_title: active_answer === item.id }">
                {{ item.title }}
                <span :class="{ active_answer: active_answer === item.id }" style="cursor: pointer;" @click="activeAns(item.id)">
                  <img src="../assets/icons/mobile/arrow.svg" alt="" />
                </span>
              </div>
              <div class="faq__item-text" v-show="active_answer === item.id" v-html="item.content">
              </div>
              <div class="lineFaq" v-if="++idx !== faq[isActiveMenu -1]?.sub_faq.length"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/UI/BreadCrumbs.vue";

export default {
  data() {
    return {
      faq: [],
      isActiveMenu: 1,
      active_answer: 0,
    };
  },
  async mounted() {
    await this.$axios.get('V1/faq')
    .then(res => {
        this.faq = res.data.data
    })
    
  },
  methods: {
    activeAns(value) {
      if (this.active_answer >= 0 && this.active_answer == value) {
        this.active_answer = -(this.active_answer = value);
      }
      else {
        this.active_answer = (this.active_answer = value);
      }
    }
  },
  components: { BreadCrumbs },
};
</script>

<style lang="scss" scoped>
.menuGap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &-item {
    padding: 0 0 0 25px !important;
  }
}
.lineFaq {
  margin-top: 32px;
  height: 1px;
  background: #E3E5E6;
  width: 100%;
}

.active_answer {
  transform: rotate(180deg);
}
.active_title {
  margin: 32px 0;
}
.active_menu {
  background: #efeded !important;
}

.faq__content {
  width: 100%;
}

.bread {
  display: flex;
  gap: 5px;
}
</style>