<template>
 <div class="content">
  
  <BreadCrumbs
        :items="[
          [$t('catalog'), '/catalog'],
          [productInfo.title, `/product/${productInfo.slug}`],
        ]"
      />
 </div>
</template>
<script>
import BreadCrumbs from './UI/BreadCrumbs.vue';

export default {
    components: { BreadCrumbs }
}
</script>
<style lang="scss" scoped>
 
</style>