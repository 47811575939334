<template>
    <div class="app">
        <MainHeader></MainHeader>
        <div class="content divdiv">
            <div class="acc-layout">
                <div class="acc-layout__menu">
                    <!-- <div class="acc-layout__member" v-if="type === 'entity'"> -->
                    <div class="acc-layout__member">
                        <div class="acc-layout__logo">
                            <img v-if="!getUser.image" :src="require('@/assets/icons/user-avatar.png')" alt="avatar"/>
                            <img v-else :src="$cdn + getUser.image" class="logoProf"/>
                        </div>
                        <div class="acc-layout__name">
                            <div class="acc-layout__status" v-if="getUser.type == 'individual'">
                                <p>ИП</p>
                            </div>
                            <div class="acc-layout__status" v-else-if="getUser.type == 'entity'">
                                <p>Юр.лицо</p>
                            </div>
                            <!-- <div class="acc-layout__status" v-else-if="getUser.type == 'physical'">
                                <p>Физ.лицо</p>
                            </div> -->
                            {{ getUser.name }}
                        </div>
                        <div class="acc-layout__member-arrow" @click="isMenuOpen = !isMenuOpen" :class="{ 'acc-layout__member-arrow-reverse': isMenuOpen }">
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <!-- <div class="acc__title" style="white-space: nowrap" v-if="type === 'individual'">
                        {{ title }}
                    </div> -->
                    <div class="acc-layout__items" :class="{ 'acc-layout__items-active': isMenuOpen }">
                        <div class="acc-layout__item" v-for="(item, i) in type === 'entity' || type === 'individual' ? acc_menu : acc_menu_ind" :key="i" @click="itemClick(item)" :class="{ acc__active: acc_active === item.id }">
                            <div class="acc-layout__icon">
                                <img :src="item.icon" alt="" />
                            </div>
                            <div class="acc-layout__page">
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="acc-layout__slot" :class="{ 'acc-layout__slot-ind': getType === 'physical' }">
                    <slot />
                </div>
            </div>
        </div>
    
        <MainFooter></MainFooter>
    </div>
</template>

<script>
import MainHeader from "../../components/Header.vue";
import MainFooter from "../../components/Footer.vue";
import { log } from "console";
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            isMenuOpen: false,
            title: "",
            acc_active: 0,
            type: null,
            acc_menu: [{
                    id: 0,
                    icon: require("../../assets/icons/acc/home.svg"),
                    text: this.$t("mainPage"),
                    link: "/main",
                },
                {
                    id: 1,
                    icon: require("../../assets/icons/acc/book.svg"),
                    text: this.$t("myPurchases"),
                    link: "/orders",
                },
                // {
                //     id: 2,
                //     icon: require("../../assets/icons/acc/book.svg"),
                //     text: this.$t("mySales"),
                //     link: "/sales",
                // },
                {
                    id: 3,
                    icon: require("../../assets/icons/mail_black.png"),
                    text: this.$t("myMessages"),
                    link: "/messanger",
                },
                {
                    id: 4,
                    icon: require("../../assets/icons/acc/comments.svg"),
                    text: this.$t("myRevieus"),
                    link: "/comments",
                },
                {
                    id: 5,
                    icon: require("../../assets/icons/like.png"),
                    text: this.$t("myFavourites"),
                    link: "/liked",
                },
                {
                    id: 6,
                    icon: require("../../assets/icons/login.png"),
                    text: this.$t("profileAccount"),
                    link: "/profile",
                },
                {
                    id: 7,
                    icon: require("../../assets/icons/acc/exit.svg"),
                    text: this.$t("exit"),
                    link: "btn",
                },
            ],
            acc_menu_ind: [{
                    id: 1,
                    icon: require("../../assets/icons/acc/book.svg"),
                    text: this.$t("myOrders"),
                    link: "/orders",
                },
                {
                    id: 3,
                    icon: require("../../assets/icons/mail_black.png"),
                    text: this.$t("myMessages"),
                    link: "/messanger",
                },
                {
                    id: 4,
                    icon: require("../../assets/icons/acc/comments.svg"),
                    text: this.$t("myRevieus"),
                    link: "/comments",
                },
                {
                    id: 5,
                    icon: require("../../assets/icons/like.png"),
                    text: this.$t("myFavourites"),
                    link: "/liked",
                },
                {
                    id: 6,
                    icon: require("../../assets/icons/login.png"),
                    text: this.$t("profileAccount"),
                    link: "/profile",
                },
                {
                    id: 7,
                    icon: require("../../assets/icons/acc/exit.svg"),
                    text: this.$t("exit"),
                    link: "btn",
                },
            ],
            member: {
                logo: require("../../assets/img/home/brand.png"),
                status: "Юр.лицо",
                name: "ТОО “Мегастрой”",
            },
        };
    },
    components: {
        MainHeader,
        MainFooter,
    },
    created() {
        this.requestUser();
        this.$store.dispatch("checkAuth");
        this.title = this.acc_menu.find(
            (item) => this.$route.fullPath === "/acc" + item.link
        ).text;
    },
    mounted() {
        this.type = localStorage.getItem("type");
        // console.log('asdasd', this.$route.path)
        this.acc_menu.map((item, index) => {
            if (this.$route.path === `/acc` + item.link) {
                this.acc_active = item.id;
            }
        });
    },
    computed: {
        ...mapGetters(["getUser", "getType"]),
    },
    methods: {
        ...mapActions(["logout", "requestUser"]),
        itemClick(item) {
            if (item.link === "btn") {
                this.logout();
                this.$toaster.error('Вы вышли с аккаунта!.')
                this.$router.push("/");
            } else {
                this.title = item.text;
                this.acc_active = item.id;
                this.$router.push(`/acc${item.link}`);
                this.isMenuOpen = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.acc__active {
    background: #efeded !important;
    &:hover {
        cursor: default !important;
    }
}

.acc-layout {
    padding: 60px 0 160px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 1200px) {
        padding: 50px 0;
        flex-direction: column;
    }
    @media (max-width: 992px) {
        padding: 16px 0 16px 0;
        flex-direction: column;
    }
    &__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        width: 16.85%;
        @media (max-width: 1200px) {
            width: 100%;
        }
        @media (max-width: 992px) {
            display: none;
        }
    }
    &__member {
        padding: 18px 16px;
        // width: 273px;
        width: 100%;
        height: 96px;
        background: #ffffff;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        gap: 9%;
        position: relative;
        &-arrow {
            display: none;
            span {
                display: inline-block;
                height: 15px;
                width: 2px;
                background-color: #282828;
                position: relative;
                &:nth-child(1) {
                    transform: rotate(-45deg);
                }
                &:nth-child(2) {
                    left: 8px;
                    transform: rotate(45deg);
                }
            }
            @media (max-width: 1200px) {
                display: block;
                width: 50px;
                height: 20px;
                position: absolute;
                top: 45%;
                right: 10px;
            }
            &-reverse {
                transform: rotate(180deg);
                right: 49px;
                top: 40%;
            }
        }
        @media (max-width: 1200px) {
            width: 100%;
            justify-content: flex-start;
            gap: 20px;
        }
    }
    &__logo {
        width: 60px;
    }
    &__name {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-weight: 500;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 12px) * 0.8333))), 18px);

        color: #202020;
    }
    &__status {
        font-weight: 400;
        font-size: min(max(8px, calc(0.5rem + ((1vw - 3.2px) * 0.375))), 14px);
        color: #727272;
        margin-bottom: 5px;
    }
    &__items {
        width: 100%;
        margin-top: 32px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
        @media (max-width: 992px) {
            width: 100%;
        }
        &-active {
            @media (max-width: 1200px) {
                display: flex;
            }
        }
    }
    &__item {
        padding: 18px 16px;
        width: 100%;
        height: 58px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        @media (max-width: 1200px) {
            width: 100%;
        }
        &:hover {
            cursor: pointer;
            background: #efeded !important;
        }
    }
    &__slot {
        &-ind {
            margin-top: 0px;
            @media (max-width: 992px) {
                margin-top: 0px;
            }
        }
        width: 79.44%;
        // @media (max-width: 1680px) {
        //     max-width: 1200px;
        // }
        // @media (max-width: 1590px) {
        //     max-width: 1050px;
        // }
        // @media (max-width: 1400px) {
        //     max-width: 900px;
        // }
        // @media (max-width: 1300px) {
        //     max-width: 800px;
        // }
        // @media (max-width: 1200px) {
        //     max-width: 100%;
        //     width: 100%;
        // }
    }
}

.acc__title {
    margin-bottom: 0px;
}
.acc-layout__slot {
    @media (max-width: 992px) {
        width: 100%;
    }
}

.logoProf {
    width: 55px !important;
    height: 55px !important;
    object-fit: cover;
    border-radius: 50%;
}
</style>
