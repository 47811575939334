<template>
  <div class="product-swiper">
    <swiper
      class="product-swiper__top"
      :modules="modules"
      :speed="400"
      :thumbs="{ swiper: thumbsSwiper }"
    >
      <template v-if="newImages[0]">
        <swiper-slide v-for="(image, i) in newImages" :key="i">
          <img v-if="image" :src="$cdn + image" alt="" />
        </swiper-slide>
      </template>
      <template v-else>
        <swiper-slide>
          <img :src="require('../assets/img/nophoto.png')" alt="" />
        </swiper-slide>
      </template>
    </swiper>
    <div class="product-swiper__container">
      <div class="product-swiper-left">
        <img src="../assets/icons/home/arrow_left.png" alt="" />
      </div>
      <swiper
        class="product-swiper__thumbs"
        :slides-per-view="5"
        :slides-per-group="1"
        :speed="550"
        :modules="modules"
        :navigation="categoryNavigation"
        :watchSlidesProgress="true"
        @swiper="setThumbsSwiper"
        @slideChange="setThumbsSwiper"
        :breakpoints="breakpoints"
      >
        <template v-if="newImages[0]">
          <swiper-slide v-for="(image, i) in newImages" :key="i">
            <img v-if="image" :src="$cdn + image" alt="" />
          </swiper-slide>
        </template>
        <template v-else>
          <swiper-slide>
            <img :src="require('../assets/img/nophoto.png')" alt="" />
          </swiper-slide>
        </template>
      </swiper>
      <div class="product-swiper-right">
        <img src="../assets/icons/home/arrow_right.png" alt="" />
      </div>
    </div>
  </div>
  <div class="mobile-swiper">
    <swiper
      style="padding: 0"
      class="mobile-swiper__top"
      slides-per-view="1"
      :modules="modules"
      :pagination="true"
      :loop="true"
      :speed="400"
    >
      <template v-if="newImages[0]">
        <swiper-slide v-for="(image, i) in newImages" :key="i">
          <img v-if="image" :src="$cdn + image" alt="" />
        </swiper-slide>
      </template>
      <template v-else>
        <swiper-slide>
          <img :src="require('../assets/img/nophoto.png')" alt="" />
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import { ref } from "vue";
import { Thumbs, FreeMode, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { mapState } from "vuex";

export default {
  name: "ThumbsSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    productImages: {
      type: Array,
      required: true,
    },
    mainImage: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.newImages.push(this.mainImage);
    this.productImages.map((item) =>
      this.mainImage !== item ? this.newImages.push(item) : null
    );
  },
  data() {
    return {
      newImages: [],
      categoryNavigation: {
        prevEl: ".product-swiper-left",
        nextEl: ".product-swiper-right",
      },
      breakpoints: {
        0: {
          spaceBetween: 10,
          slidesPerView: 4,
        },
        1366.98: {
          spaceBetween: 10,
        },
      },
    };
  },
  watch: {
    mainImage() {
      this.newImages = [];
      this.newImages.push(this.mainImage);
      this.productImages.map((item) =>
        this.mainImage !== item ? this.newImages.push(item) : null
      );
    },
  },
  setup() {
    let thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    return {
      modules: [Thumbs, FreeMode, EffectFade],
      thumbsSwiper,
      setThumbsSwiper,
    };
  },
};
</script>

<Style lang="scss" scoped>
$pc: 1466;
$tablet: 992;
$mobile: 667;
$mobileSmall: 520;
.swiper-slide {
  width: 100%;
}
.swiper-pagination-bullets {
  top: 90% !important;
}
.swiper-pagination-bullet-active {
  background: #282828 !important;
}
.mobile-swiper {
  margin-bottom: -20px;
  display: none;
  width: 100%;
  @media (max-width: $mobile + px) {
    display: block;
    height: 315px;
    &__top {
      height: 100%;
      img {
        width: 100%;
        height: 250px;
      }
    }
  }
}
.product-swiper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0 15px;
  height: 563px;
  width: 40.93%;
  &-right,
  &-left {
    display: block !important;
    width: 8px;
    height: 12.33px;
    filter: contrast(10%);
    &:hover {
      cursor: pointer;
      filter: contrast(100%);
    }
  }
  .swiper-button-disabled {
    visibility: hidden;
  }
  @media (max-width: $pc + px) {
    max-width: 503px;
    height: 463px;
  }
  @media (max-width: 1450px) {
    height: 463px;
  }
  @media (max-width: $tablet + px) {
    width: 100%;
    margin: 0 auto 0 0;
  }
  @media (max-width: $mobile + px) {
    display: none;
    height: 369.5px;
  }
  @media (max-width: $mobileSmall + px) {
    height: 276px;
    gap: 0 21px;
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 24px 0;
    @media (max-width: $mobileSmall + px) {
      padding: 17px 0;
    }
  }
  &__top {
    height: 100%;
    .swiper-slide {
      /* border: 1px solid #ececec;
      border-radius: 10px; */
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: contain;
        height: 90%;
        max-width: 100%;
        max-height: 90%;
      }
    }
    @media (max-width: $tablet + px) {
      width: 100% !important;
    }
  }

  &__thumbs {
    .swiper-slide {
      border: 1px solid white;
      padding: 5px;
      /* border-radius: 10px; */
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: contain;
        height: 80px;
        pointer-events: none;
      }
    }

    @media (max-width: $tablet + px) {
      width: 100% !important;
    }
  }

  &__thumbs {
    .swiper-slide {
      cursor: pointer;
      &-thumb-active {
        border-color: #282828;
      }
    }
    @media (max-width: $pc + px) {
      width: 80px;
    }
    @media (max-width: $mobile + px) {
      width: 63px;
    }
    @media (max-width: $mobileSmall + px) {
      width: 46px;
    }
  }

  &__top,
  &__thumbs {
    width: 614px;
    &.swiper {
      margin: 0;
    }
    @media (max-width: $pc + px) {
      width: 514px;
    }
    @media (max-width: $mobile + px) {
      width: 454.5px;
    }
    @media (max-width: $mobileSmall + px) {
      width: 276px;
    }
  }
}
</Style>