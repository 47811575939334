<template>
    <div class="application">
        <div class="content">
            <div class="application-header">
                <div class="auth-header__icon">
                    <img src="../../assets/icons/auth/registration_end.svg" alt="">
                </div>

            </div>
            <div class="application__wrapper">
                <div class="application__background">
                    <img src="../../assets/img/application2.png" alt="">
                </div>
                <p class="yourRevSended">Ваша заявка отправлена!</p>
                <div class="application__text">
                    Ваша заявка успешно отправлена и будет обработана менеджером в ближайшее время
                </div>
                <!-- <a href="/payment.docx" download="" class="application__bill" v-if="getType !== 'physical'">
                    <div class="application__doc">
                        <img src="../../assets/icons/auth/doc.svg" alt="">
                    </div>
                    Скачать счет на оплату
                </a> -->
                <button class="button button_application" @click="$router.push('/')">
                    <div class="application__back">
                        <img src="../../assets/icons/auth/arrow_back_white.svg" alt="">

                    </div>
                    Вернуться на главную страницу
                </button>
            </div>

        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(["getType"])
    }
}
</script>

<style lang="scss" scoped>
.yourRevSended {
    margin-top: 32px;
    font-size: min(max(22px, calc(1.375rem + ((1vw - 3.2px) * 0.625))), 32px);
    font-weight: 600;
}
.application__wrapper {
    margin-top: 60px;
}
</style>