<template>
  <div>
    <div class="cities">
      <div class="cities__background" @click="openCitiesModal()"></div>
      <div class="cities__block">
        <h3 class="cities__title">{{ $t("chooseCity") }}</h3>
        <div class="cities__input">
          <input
            class="inputCity"
            @input="searchCities($event.target)"
            type="text"
            placeholder="Введите название города"
          />
          <img :src="require('@/assets/icons/home/search.png')" />
        </div>
        <div class="cities__items" v-if="cities">
          <div
            class="cities__item"
            v-for="item in findCities"
            :key="item.id"
            @click="selectCity(item)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="cities__close" @click="openCitiesModal()">
          <img src="../assets/icons/components_icon/close.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cities: [],
      findCities: [],
    };
  },
  async mounted() {
    await this.$axios
      .get(`V1/cities`)
      .then((response) => {
        this.cities = response.data.data;
      })
      .catch((e) => {
        // console.log(e);
      });
    this.findCities = this.cities;
  },
  methods: {
    selectCity(item) {
      localStorage.setItem("city", JSON.stringify(item));
      this.$emit("select-city", item);
      document.querySelector(".inputCity").value = "";
      this.findCities = this.cities;
    },
    openCitiesModal() {
      this.$emit("open-cities-modal");
      document.querySelector(".inputCity").value = "";
      this.findCities = this.cities;
    },
    searchCities(e) {
      if (e.value.charAt(0) === " ") {
        e.value = "";
      }
      e.value !== ""
        ? (this.findCities = this.cities.filter((item) =>
            item.title.toLowerCase().includes(e.value.toLowerCase())
          ))
        : (this.findCities = this.cities);
    },
  },
};
</script>

<style lang="scss" scoped>
.cities {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  top: 0%;
  left: 0%;
  z-index: 110;

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.4;
    height: 100vh;
    width: 100vw;
  }

  &__block {
    position: absolute;
    border-radius: 24px;
    top: 50%;
    left: 50%;
    height: 85vh;
    width: 90vw;
    transform: translate(-50%, -50%);
    padding: 30px 50px;
    background-color: #fff;
  }

  &__title {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
  }

  &__items {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    height: 90%;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px 20px;
  }

  &__item {
    font-size: 18px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__close {
    cursor: pointer;
    width: 20px;
    position: absolute;
    right: 30px;
    top: 30px;
  }

  &__input {
    display: none;
  }
  @media screen and (max-width: 992px) {
    &__block {
      width: 100vw;
      height: 100vh;
      overflow-y: hidden;
      overflow-x: hidden;
      border-radius: 0;
      padding: 22px;
    }

    &__items {
      height: 80%;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 1%;
      overflow-y: scroll;
      row-gap: 32px;
    }
    &__item {
      width: 49.5%;
      font-weight: 600;
      font-size: 16px;
    }
    &__title {
      text-align: left;
      font-size: 16px;
    }
    &__close {
      cursor: pointer;
      width: 16px;
      position: absolute;
      right: 22px;
      top: 22px;
    }
    &__input {
      display: flex;
      align-items: center;
      border: 1px solid #c2c2c2;
      width: 100%;
      padding: 12px;
      margin: 30px 0 30px 0;
      input {
        border: none;
        outline: none;
        flex-grow: 1;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.cities__items {
  gap: 20px;
}
.cities__item {
    font-size: min(max(12px, calc(0.75rem + ((1vw - 12px) * 0.8333))), 18px);
}
</style>