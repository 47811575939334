<template>
  <div class="basket">
    <div class="content">
      <div class="basket__wrapper">
        <h2 class="basket__title">{{ $t("basket") }}</h2>
      </div>
      <div class="basket__active" v-if="items.length !== 0">
        <div class="basket__info-input">
          <input
            type="checkbox"
            value="newsletter"
            :checked="isChecked"
            @click="selectAllItems()"
          />

        </div>
        <div class="basket__active-all">{{ $t("chooseAll") }}</div>
        <div
          class="basket__active-delete"
          style="cursor: pointer"
          @click="deleteSelectedItems()"
        >
          {{ $t("deleteSelected") }}
        </div>
      </div>
      <div class="basket__none" v-if="items.length == 0">
        {{ $t("cartEmpty") }}
      </div>
      <div class="basket__main" v-else>
        <div class="basket__items">
          <div class="basket__item" v-for="(item, idx) in items" :key="item.id">
            <div class="basket__item-title">
              <div class="basket__item-order">{{ $t('orderNumber') }} №{{ ++idx }}</div>
              <div class="basket__item-vendor">{{ $t('postavchik') }}: Кто-то</div>
            </div>
            <div class="basket__item-delivery">
              <div class="basket__item-delivery-info">
                <div class="basket__item-delivery-icon">
                  <img src="../assets/icons/basket/box.svg" alt="" />
                </div>
                <div class="basket__item-delivery-text">
                  {{ $t("pickup") }} - Уральск, Аксай, Атырау
                </div>
              </div>
              <div class="basket__item-delivery-info">
                <div class="basket__item-delivery-icon">
                  <img src="../assets/icons/basket/car.svg" alt="" />
                </div>
                <div class="basket__item-delivery-text">
                  Завтра 21 мая, от 1 000 ₸
                </div>
              </div>
            </div>
            <div class="desctop_basket">
              <div class="basket__info">
                <div class="basket__info-input">
                  <input
                    type="checkbox"
                    :value="item.id"
                    :id="item.id"
                    v-model="checkedBrands"
                  />
                </div>
                <router-link :to="'/product/' + item.slug" class="basket__info-img" style="cursor: pointer;">
                  <img :src="$cdn + item.main_image" alt="IMG" />
                </router-link>
                <div class="basket__info-about">
                  <router-link :to="'/product/' + item.slug"  class="basket__info-name"  style="cursor: pointer;">
                    {{ item.title }}
                  </router-link>
                  <div class="basket__info-amount">
                    {{ item.quantity }} {{ $t("pc") }}
                  </div>
                </div>
                <div class="basket__info-cost">
                  {{ item.current_price }} ₸/{{ $t("pc") }}
                </div>
                <div class="basket__info-count">
                  <button
                    class="button button_basket"
                    @click="minusItemCount(item)"
                  >
                    -
                  </button>
                  <span> {{ item.quantity }} {{ $t("pc") }} </span>
                  <button
                    class="button button_basket"
                    @click="plusItemCount(item)"
                  >
                    +
                  </button>
                </div>
                <div class="basket__info-total">
                  {{ item.current_price * item.quantity }} ₸
                </div>
                <div class="basket__info-delete" @click="deleteItem(item)">
                  <img
                    style="cursor: pointer"
                    src="../assets/icons/basket/delete.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="mobile_basket">
              <div class="basket__info">
                <div class="basket__info-first">
                  <div class="basket__info-input">
                    <input
                      type="checkbox"
                      :value="item.id"
                      :id="item.id"
                      v-model="checkedBrands"
                    />
                    <!-- <input
                      :valueC="item.id"
                      :idC="item.id"
                      vModelC="checkedBrands"
                    /> -->
                  </div>
                  <div class="basket__info-img" style="cursor: pointer;" @click="$router.push(`/product/${ item.slug }`)">
                    <img :src="$cdn + item.main_image" alt="IMG"/>
                    <!-- @click="sendSingleProduct(productData.id, productData.slug) -->
                  </div>
                  <div class="basket__info-about">
                    <div class="basket__info-name" style="cursor: pointer;" @click="$router.push(`/product/${ item.slug }`)">
                      {{ item.title }}
                    </div>
                    <div class="basket__info-amount">
                      {{ item.quantity }}
                    </div>
                  </div>
                  <div class="basket__info-delete" @click="deleteItem(item)">
                    <img src="../assets/icons/basket/delete.svg" alt="" />
                  </div>
                </div>
                <div class="basket__info-second">
                  <div class="basket__info-costs">
                    <div class="basket__info-cost">
                      {{ item.current_price }} ₸/{{ $t("pc") }}
                    </div>
                    <div class="basket__info-total">
                      {{ item.current_price * item.quantity }} ₸
                    </div>
                  </div>
                  <div class="basket__info-count">
                    <button
                      class="button button_basket"
                      @click="minusItemCount(item)"
                    >
                      -
                    </button>
                    <span>
                      {{ item.quantity }}
                    </span>
                    <button
                      class="button button_basket"
                      @click="plusItemCount(item)"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="basket__card">
          <div class="basket__card-total">
            <span>{{ $t("total") }}</span>
            <span>{{ cost_with_sale.toFixed(2) }} ₸</span>
          </div>
          <div class="basket__card-goods">
            {{ $t("products") }}, {{ this.goods_count }} {{ $t("pc") }}
            <span> {{ this.total_cost }} ₸ </span>
          </div>
          <div class="basket__card-sale" v-if="this.sale">
            {{ $t("discount") }}
            <span> - {{ this.sale.toFixed(2) }} ₸ </span>
          </div>
          <button
            v-if="!getIsAuth"
            class="button button_basket-card"
            @click="checkInformationAndRouterPush()"
          >
            {{ $t("goCheckout") }}
          </button>

          <router-link to="/my-order"
            v-else
            class="button button_basket-card"
          >
            {{ $t("goCheckout") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      total_cost: 0,
      good_count: 1,
      checkedBrands: [],
      isChecked: false,
      sale: 1000000,
      cost_with_sale: 122222,
      goods_count: 0,
      item_count: 12,
      checkboxes: [],
      items: [],
      items_for_delete: [],
    };
  },
  watch: {
    checkedBrands() {
      this.isChecked =
        this.checkedBrands.length === this.items.length ? true : false;
      // console.log(this.isChecked);
    },
  },
  async created() {
    if (this.$store.state.card == []) {
      // console.log("111");
    } else {
      await this.setBacketCards();
      this.setAddMenu();
    }
    // console.log(this.items);
  },
  computed: {
    ...mapGetters(["STATE", 'getIsAuth']),
  },
  methods: {
    selectAllItems() {
      if (this.isChecked == false) {
        this.checkedBrands = [];
        this.items.forEach((element) => {
          // console.log("sadsad");
          this.checkedBrands.push(element.id);
          element.isChecked = true;
          this.items_for_delete.push(element);
        });
      } else {
        this.items.forEach((element) => {
          // console.log("fdhfg");
          this.checkedBrands = [];
          element.isChecked = false;
          this.items_for_delete = [];
        });
      }
      this.isChecked = !this.isChecked;
    },
    deleteSelectedItems() {
      this.checkedBrands.forEach((item) => {
        this.deleteItem({
          id: item,
        });
      });
      this.isChecked = false;
      // console.log("asdasdas");
    },
    goodsCount() {
      let total_count = 0;
      for (let i = 0; i < this.items.length; i++) {
        total_count += this.items[i].count;
      }
      return total_count;
    },
    setAddMenu() {
      this.total_cost = 0;
      this.goods_count = 0;
      this.cost_with_sale = 0;
      this.sale = 0;
      // console.log(this.items);
      for (let i = 0; i < this.items.length; i++) {
        this.goods_count += this.items[i].quantity;
        this.total_cost += this.items[i].current_price * this.items[i].quantity;
        if (this.items[i].sale) {
          this.sale +=
            ((this.items[i].current_price * this.items[i].quantity) / 100) *
            this.items[i].sale;
        }
        this.cost_with_sale = this.total_cost - this.sale;
      }
    },
    async setBacketCards() {
      this.items = [];
      this.items = this.$store.state.card;
    },
    checkInformationAndRouterPush() {
        this.$store.state.openEnterModal = true;
    },
    minusItemCount(item) {
      if (item.quantity <= 0) {
        this.deleteItem(item);
      } else if (item.fixcount == item.quantity) {
        return;
      } else {
        item.quantity = item.quantity - 1;
        this.setAddMenu();
      }
    },
    plusItemCount(item) {
      this.$store.dispatch("ADD_PRODUCT_TO_BASKET", item);
      this.setAddMenu();
    },
    deleteItem(item) {
      this.$store.commit("DELETE_BASKET_ITEM", item.id);
      this.items = this.$store.state.card;
      this.setAddMenu();
    },
    // totalCost() {
    //     let cost = 0;
    //     for (let i = 0; i < this.items.length; i++) {
    //         cost = this.items[i].cost * this.items[i].count
    //         cost += cost
    //         console.log('cost', cost);
    //     }
    //     this.total_cost = cost
    // }
  },
};
</script>

<style lang="scss" >
.active_input {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 18px;
  width: 18px;
  background: url("@/assets/icons/basket/check.svg");
}

.mobile_basket {
  @media (max-width: 991px) {
    width: 100%;
  }
}
.desctop_basket {
  width: 100%;
}
</style>