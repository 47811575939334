<template>
    <div class="privacy content">
      <div class="bread"> 
          <router-link to="/" style="color: black"><p>{{ $t('main') }}</p></router-link>
         <div style="opacity: 0.3;">•</div>
         <p style="opacity: 0.7;">{{ $t('privacyPolicy') }}</p>
      </div>
      <div class="privacy__content">
        <p class="privacy__content-p1" v-html="policy.title"></p>
        <p v-html="policy.description">
        </p>
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      policy: null
    }
  },
  created() {    
    this.$axios.get('V1/page-by-slug?lang=ru&slug=personal_data')
      .then(res => {
        this.policy = res.data.data[0]
      })
      .catch(err => {
        // console.log(err);
      })
  },
    
}
</script>
<style lang="scss" scoped>

.bread {
  display: flex;
  gap: 5px;
  margin-bottom: 32px;
}
.privacy {
    padding: 60px 0;
    &__content {
      &-p1 {
          font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 0.5))), 26px);
          margin-bottom: 16px !important;
          font-weight: 600;
      }
      p {
        margin: auto;
        padding: 0;
        line-height: normal;
      }
    }
}
</style>