<template>
  <div class="header-modal">
    <div class="header-modal__bg" @click.self="$emit('open-comment-modal')">
      <div class="modal__take otzivNew">
        <div class="modal__take-close otzivNew-close">
          <div class="modal__take-icon" @click="$emit('open-comment-modal')">
            <img src="../assets/icons/components_icon/close.svg" alt="" />
          </div>
        </div>

        <!-- <div class="modal__take-end" v-show="isFeedbackPosted">
          <div class="modal__take-end-text">
           {{ $t("yourFeedback") }}
          </div>
          <button class="button button_take" @click="closeButton">
            Хорошо
          </button>
        </div> -->

        <div class="okk" v-if="isFeedbackPosted">
          <div class="okk-p1">
            Благодарим Вас за оценку и выбор нашего товара!
          </div>
          <button class="okk-button" @click="closeButton">
            Закрыть
          </button>
        </div>

        <div class="modal__take-wrapper otzivNew__content" v-show="!isFeedbackPosted">
          <div class="modal__take-title otzivNew__content-title">{{ $t("leaveReview") }}</div>
          <!-- {{ product }} -->
          <div class="modal__take-product xxw">
            <img :src="$cdn + product.main_image" />
            <div class="lineOtziv1"></div>
            <div class="text">
              {{ product.title }}
              <!-- <div class="raw">
                <div class="product__card-item">
                  <div class="product__card-icon">
                    <img src="../assets/icons/mail_black.png" alt="" />
                  </div>
                  <div class="product__card-text">
                    {{ $t("pickup") }} - Уральск, Аксай, Атырау
                  </div>
                </div>
                <div class="product__card-item">
                  <div class="product__card-icon">
                    <img src="../assets/icons/location_black.png" alt="" />
                  </div>
                  <div class="product__card-text">
                    {{ this.$t("delivery") }} - завтра, 21 мая, от 1000 тг
                  </div>
                </div>
              </div> -->
            </div>
            <div class="lineOtziv"></div>
          </div>
          <div class="modal__take-rating otzivNew__content-rating">
            <div class="modal__take-rating-stars">
              <RatingSelectVue
                @radioPickedValue="radioPicked"
              ></RatingSelectVue>
            </div>
            <!-- <div class="modal__take-rating-text">{{$t("yourRating")}}</div> -->
          </div>
          <div class="modal__take-input otzivNew__content-input">
            <textarea
              maxlength="1000"
              v-model="feedback.advantages"
              type="text"
              :placeholder="$t('writeFeedback')"
            ></textarea>
            <p class="otzivNew__content-textLength">{{ feedback.advantages.length }} / 1000</p>
          </div>
          <p class="otzivNew__content-thx">Спасибо, что выбрали нас!</p>
          <button class="button button_take otzivNew__content-button" @click="clickOnSend()">
            {{ $t("send") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RatingSelectVue from "./RatingSelect.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      feedback: {
        rating: null,
        advantages: "",
        disadvantages: "",
      },
      isFeedbackPosted: false,
    };
  },
  props: ["product"],
  created() {
    this.isFeedbackPosted = false;
  },
  computed: {
    ...mapGetters(["getUser"])
  },
  methods: {
    closeButton() {
      this.isFeedbackPosted = false;
      this.$emit("open-comment-modal");
    },
    radioPicked(value) {
      this.feedback.rating = value;
    },
    async clickOnSend() {
      if (this.feedback.rating == null || this.feedback.text == "") {
        // alert("введите данные");
      this.$toaster.error('Ваш отзыв не отправлен.')
      } else {
        let data = new FormData();
        data.append("name", this.getUser.name);
        data.append("review", this.feedback.advantages);
        data.append("rating", this.feedback.rating);
        data.append("product_id", this.product.id);
        await this.$axios.post("V1/review", data,  {
        headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        })
        .then(res => {
          this.$toaster.success('Ваш отзыв успешно отправлен')
          this.$emit("send-info", this.feedback);
          this.feedback = {
            rating: null,
            advantages: "",
          };
          this.isFeedbackPosted = true;
        })
        .catch(err => {
          this.$toaster.error('Неправильно заполнено')
        })
      }
    },
  },
  components: {
    RatingSelectVue,
  },
};
</script>

<style lang="scss" scoped>

.okk {
  margin-top: 32px;
  padding-bottom: 22px;
  display: flex;
  flex-direction: column;
  gap: 45px;
  justify-content: center;
  align-items: center;
  &-p1 {
      font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.6923))), 25px);
  }
  &-button {
    cursor: pointer;
    width: 52%;
    font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.6923))), 25px);
    color: white;
    background: #000000;
    height: 55px;
    border: 1px solid transparent;
    transition: all 0.5s ease;
    opacity: 0.8;
    &:hover {
      border: 1px solid black;
      opacity: 1;
    }
  }
}

.header {
  &-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;

    &__bg {
      position: fixed;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.36);
      z-index: -1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.modal__take {
  padding: 16px 16px 20px;
  width: 900px;
  height: auto;
  background: #ffffff;

  &-product {
    width: 100%;
    margin-top: 34px;
    display: flex;
    gap: 20px;
    .text {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .raw {
        display: flex;
        font-size: 15px;
        gap: 20px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
  }

  &-end {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-close {
    display: flex;
    justify-content: flex-end;
  }

  &-wrapper {
    padding: 0 39px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 576px) {
      padding: 0 10px;
    }
  }

  &-icon {
    width: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &-title {
    margin-top: 40px;
    font-weight: 600;
    font-size: 32px;
    color: #202020;
    text-align: center;
  }

  &-subtitle {
    margin-top: 32px;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #727272;
  }

  &-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #202020;
      margin-top: 10px;
    }
  }

  &-input {
    margin-top: 30px;
    width: 100%;

    textarea {
      width: 100%;
      border: 1px solid #727272;
      padding: 15px;
      height: 150px;
      overflow-wrap: break-word;
      color: #282828;
      font-size: 16px;
      margin-top: 20px;
    }
  }
}
.xxw {
  display: flex;
  flex-direction: column;
  img {
    width: 20%;
    height: auto;
  }
}
.lineOtziv1 {
  height: 1px;
  background: #00000024;
  width: 20%;
}
.lineOtziv {
  height: 1px;
  background: #00000024;
  width: 100%;
}


.otzivNew {
  position: relative;
  padding: 80px 45px 60px 45px;
  width: 800px;
  &-close {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }
  &__content {
    padding: 0;
    &-title {
      margin-top: 0;
    }
    &-rating {
      margin-right: auto;
    }
    &-input {
      position: relative;
      margin-top: 0;
      textarea {
        border: 1px solid rgba(0, 0, 0, 0.14);
        height: 160px !important;
      }
    }
    &-textLength {
      color: #727272;
      bottom: 5%;
      right: 2%;
      position: absolute;
    }
    &-button {
      font-weight: 400;
      font-size: 20px;
      margin-top: 0;
      width: 35%;
      height: 52px;
    }
    &-thx {
      margin: 15px 0;
    }
  }
}
</style>