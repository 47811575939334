<template>
  <div class="search">
    <div class="content">
      <BreadCrumbs :items="[[$t('resultSearch'), $route.fullPath]]" />
      <div class="singleCategory__title">{{ $t("resultSearch") }}</div>
      <div class="search__subtitle">
        {{
          $t("foundRequest", {
            res: $route.params.result,
            len: products.length,
          })
        }}
      </div>
      <div class="search__filters">
        <select class="search__input">
          <option value="all">Все</option>
          <option value="min">По цене</option>
          <option value="max">По имени</option>
        </select>
        <select class="search__input">
          <option value="all">Все</option>
          <option value="min">По цене</option>
          <option value="max">По имени</option>
        </select>
      </div>
      <div class="search__wrapper">
        <div class="search__result" v-for="item in products" :key="item.id">
          <Card :product="item"></Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import BreadCrumbs from "@/components/UI/BreadCrumbs.vue";
import { mapActions, mapGetters } from "vuex";
import Card from "../components/GoodsCard.vue";
export default {
  data() {
    return {
      product_amount: null,
      result: [],
      products: [],
    };
  },
  async created() {
    await this.GET_PRODUCT_LIST();
    this.setResult();
  },
  methods: {
    async getProducts() {
      await this.$axios
        .get(`search`, {
          params: {
            text: this.$route.params.result,
          },
        })
        .then((res) => {
          this.products = res.data.products;
        })
        .catch(() => {});
    },
    setResult() {
      this.result = this.STATE.product;
      this.product_amount = this.result.length;
    },
    ...mapActions(["GET_PRODUCT_LIST"]),
  },
  created() {
    this.getProducts();
  },
  watch: {
    $route() {
      this.getProducts();
    },
  },
  computed: {
    ...mapGetters(["STATE"]),
  },
  components: {
    Card,
    BreadCrumbs,
  },
};
</script>

<style lang="scss">
.search {
  padding: 100px 0;
  &__filters {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    align-items: center;

    select {
      padding: 10px 12px;
      width: 273px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #e3e5e6;
    }
  }

  &__subtitle {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #727272;
  }

  &__wrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__result {
    width: 24%;

    @media (max-width: 1200px) {
      width: 32%;
    }

    @media (max-width: 768px) {
      width: 50%;
    }
  }
  @media (max-width: 768px) {
    padding: 50px 0;
  }
}
</style>