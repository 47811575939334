<template>
  <div
    class="backPartner block animated fadeIn"
    v-scroll
    v-if="$store.state.openPartnersModal == true"
    @click="$store.state.openPartnersModal = false"
  >
      <div class="partner" @click.stop="hideSelect">
        <img
          src="@/assets/icons/close.svg"
          class="closeIcon"
          @click="$store.state.openPartnersModal = false"
        />
        <div class="partner__class">
          <p class="partner__class-title">{{ $t("partners") }}</p>
          <div class="partnerReg">
            <div class="partnerReg__left">
              <div class="partnerReg__left__input">
                <p>{{ $t("Название компании") }}</p>
                <input
                  type="text"
                  v-model="v$.name.$model"
                  :placeholder="$t('Введитена именованиекомпании')"
                />
                <p v-if="v$.name.$error" class="errorData">
                  Обьязательное поле
                </p>
              </div>
              <div class="partnerReg__left__input">
                <p>{{ $t("БИН/ИИН") }}</p>
                <input
                  type="text"
                  maxlength="12"
                  :placeholder="$t('Введите БИН')"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  v-model="v$.bin.$model"
                  id="bin-input"
                />
                <span v-if="v$.bin.$error" class="errorData"
                  >БИН должен состоять из 12 цифр</span
                >
              </div>

              <div class="partnerReg__left__input">
                <p>{{ $t("Форма регистрации") }}</p>

                <Treeselect
                  class="select"
                  v-model="typeReg"
                  :disable-branch-nodes="true"
                  :show-count="true"
                  :options="typeRegOptions"
                  placeholder="Выберите форму регистрации компании"
                  />
              </div>
              
              <div class="selectValue partnerReg__left__input" @click.stop>
                <p>Город</p>
                <!-- <input type="search" v-model="searchText" @input="filteredItems" @click="allItems" />
                <div class="selectValue__values">
                  <div
                    v-for="item in selCities"
                    :key="item.id"
                    class="selectValue__values-city"
                  >
                    <p @click="selectCityValue(item.title)">{{ item.title }}</p>
                  </div>
                </div> -->

                 <Treeselect
                  class="select"
                  v-model="searchText"
                  :disable-branch-nodes="true"
                  :show-count="true"
                  :options="typeRegOptionsWithTitle"
                  placeholder="Выберите город"
                  />
              </div>

              <div class="partnerReg__left__input">
                <p>{{ $t("Юридический адрес") }}</p>
                <input
                  type="text"
                  v-model="v$.addressLegal.$model"
                  :placeholder="$t('Введите юридический адрес компании')"
                />
                <p v-if="v$.addressLegal.$error" class="errorData">
                  Обьязательное поле
                </p>
              </div>

              <div class="partnerReg__left__input">
                <p>{{ $t("Фактический адрес") }}</p>
                <input
                  type="text"
                  v-model="v$.address.actual.$model"
                  :placeholder="$t('Введите фактический адрес компании')"
                />
                <p v-if="v$.address.actual.$error" class="errorData">
                  Введите корректный адрес
                </p>
              </div>

            </div>
            <div class="partnerReg__left">
              <div class="partnerReg__left__input">
                <p>{{ $t("Номер телефона компании") }}</p>
                <input
                  type="text"
                  v-model="v$.numberCompany.$model"
                  @input="isNumber"
                  v-imask="phoneMask"
                  @accept="onAccept"
                  @complete="onComplete"
                  :placeholder="$t('Введите номер телефона компании')"
                />
                <p v-if="v$.numberCompany.$error" class="errorData">
                  Введите номер формата +7(777)777 77 77
                </p>
              </div>

              <div class="partnerReg__left__input">
                <p>{{ $t("E-mail компании") }}</p>
                <input
                  type="text"
                  v-model="v$.email.$model"
                  :placeholder="$t('Введите e-mail компании')"
                />
                <p v-if="v$.email.$error" class="errorData">
                  Введите корректный Email
                </p>
              </div>
              
              <div class="partnerReg__left__input">
                <p>{{ $t("ФИО представителя компании") }}</p>
                <input
                  type="text"
                  v-model="v$.nameCompanyPerson.$model"
                  :placeholder="$t('Введите Ваше ФИО')"
                  @keyup="onlyText()"
                />
                <p v-if="v$.nameCompanyPerson.$error" class="errorData">
                  Обьязательное поле
                </p>
              </div>

              
              <div class="partnerReg__left__input">
                <p>{{ $t("Номер мобильного телефона представителя") }}</p>
                <input
                  type="text"
                  v-model="v$.number.$model"
                  @input="isNumber"
                  v-imask="phoneMask"
                  @accept="onAccept"
                  @complete="onComplete"
                  :placeholder="$t('Введите номер телефона представителя')"
                />
                <p v-if="v$.number.$error" class="errorData">
                  Введите номер формата +7(777)777 77 77
                </p>
              </div>
              
              <div class="partnerReg__left__input">
                <p>{{ $t("E-mail представителя компании") }}</p>
                <input
                  type="text"
                  v-model="v$.emailCompany.$model"
                  :placeholder="$t('Введите ваш e-mail')"
                />
                <p v-if="v$.emailCompany.$error" class="errorData">
                  Введите корректный Email
                </p>
              </div>
              <div class="partnerReg__left__input">
                <button @click="submitForm">{{ $t("Отправить") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import Treeselect from "vue3-treeselect";
import {
  required,
  minLength,
  maxLength,
  email,
  helpers,
  sameAs,
} from "@vuelidate/validators";
import { IMaskDirective } from "vue-imask";
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import { mapGetters, mapMutations } from "vuex";
import SelectComponentFilter from "./UI/SelectComponentFilter.vue";
const toaster = createToaster({ position: "top-right" });
export default {
    directives: {
        imask: IMaskDirective,
    },
    data() {
        return {
            typeReg: 1,
            selectedItem: null,
            searchText: "Выберите город",
            v$: useVuelidate(),
            selCities: [],
            name: "",
            nameCompanyPerson: "",
            bin: "",
            address: {
                legal: "",
                actual: "",
            },
            city: "",
            number: "",
            numberCompany: "",
            email: "",
            emailCompany: "",
            addressLegal: "",
            operators: [
                700,
                701,
                702,
                703,
                704,
                705,
                706,
                707,
                708,
                709,
                747,
                750,
                751,
                760,
                761,
                762,
                763,
                764,
                771,
                775,
                776,
                777,
                778,
            ],
            phoneMask: {
                mask: "+{7} ({000}) 000-00-00",
                lazy: true,
            },
            binMask: {
                mask: "############",
                lazy: true,
            },
          typeRegOptions: [{
              id: 1,
              label: 'Юридическое лицо'
              },
              {
                  id: 2,
                  label: 'ИП',
              }
            ]
        };
    },
    validations() {
        return {
            name: { required, minLength: minLength(2) },
            bin: { required, minLength: minLength(12), maxLength: maxLength(12) },
            email: { required, email },
            emailCompany: { required, email },
            // number: { required },
            number: {
                required: helpers.withMessage(this.$t("ошибка"), required),
                minLength: helpers.withMessage(this.$t("непврв"), minLength(18)),
            },
            numberCompany: { required },
            addressLegal: { required, minLength: minLength(2) },
            nameCompanyPerson: { required, minLength: minLength(2) },
            address: {
                actual: { required },
            },
        };
  },
    computed: {
      typeRegOptionsWithTitle() {
        return this.city.map(option => {
          return {
            id: option.title,
            label: option.title
          }
        })
      }
    },
    async mounted() {
        if (JSON.parse(localStorage.getItem("city")).title) {
            this.searchText = JSON.parse(localStorage.getItem("city")).title;
        }
        await this.$axios
            .get(`V1/cities`)
            .then((response) => {
            this.city = response.data.data;
        })
            .catch((err) => { });
    },
    methods: {
        hideSelect() {
            document.querySelector(".selectValue__values")?.classList?.remove("selectValue__valuesNone");
            document.querySelector(".selectBecome")?.classList?.remove("selectBecomeRotate");
        },
        filteredItems() {
            if (!this.searchText.trim()) {
                return [];
            }
            let newFilter = this.city.filter((item) => item.title.toLowerCase().includes(this.searchText.toLowerCase()));
            if (newFilter.length) {
                document
                    .querySelector(".selectValue__values")
                    ?.classList?.add("selectValue__valuesNone");
                document.querySelector(".selectBecome")?.classList?.add("selectBecomeRotate");
            }
            else {
                document
                    .querySelector(".selectValue__values")
                    ?.classList?.remove("selectValue__valuesNone");
                document.querySelector(".selectBecome")?.classList?.remove("selectBecomeRotate");
            }
            if (newFilter[0].title === this.searchText) {
                document
                    .querySelector(".selectValue__values")
                    ?.classList?.remove("selectValue__valuesNone");
                document.querySelector(".selectBecome")?.classList?.remove("selectBecomeRotate");
            }
            this.selCities = newFilter;
        },
        allItems() {
            this.selCities = this.city;
            document
                .querySelector(".selectValue__values")
                ?.classList?.add("selectValue__valuesNone");
            document.querySelector(".selectBecome")?.classList?.add("selectBecomeRotate");
        },
        onlyText() {
            this.nameCompanyPerson = this.nameCompanyPerson.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
            this.nameCompanyPerson = this.nameCompanyPerson.replace(/\.{2,}|\s{2,}|\-{2,}/g, function (match) {
                return match.substr(0, 1);
            });
        },
        selectCityValue(value) {
            this.searchText = value;
            document
                .querySelector(".selectValue__values")
                .classList.remove("selectValue__valuesNone");
            document.querySelector(".selectBecome")?.classList?.remove("selectBecomeRotate");
        },
        submitForm() { },
        isNumber(e) {
            let regex = /[0-9]/;
            if (!regex.test(e.key)) {
                e.returnValue = false;
                if (e.preventDefault)
                    e.preventDefault();
            }
            if (e.target.value.slice(4, 7).length === 3) {
                if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
                    // console.log("true", true);
                }
                else {
                    e.target.value = " ";
                    toaster.warning("Такого мобильного оператора не существует!");
                }
            }
        },
        onAccept(e) {
            const maskRef = e.detail;
            this.value = maskRef.value;
        },
        onComplete(e) {
            const maskRef = e.detail; // что бы в дальнейшем сохранить
            // console.log("complete", maskRef.unmaskedValue);
        },
        async submitForm() {
            this.v$.$validate();
            if (!this.v$.$invalid) {
                const data = {
                    title: this.name,
                    bin: this.bin,
                    phone: this.number,
                    email: this.email,
                    legal_address: "",
                    description: "",
                    address: this.searchText,
                    type: this.typeReg,
                    admin: this.nameCompanyPerson,
                    admin_phone: this.numberCompany,
                    admin_email: this.emailCompany,
                    legal_address: this.addressLegal
                };
                await this.$axios.post("/V1/shopper-form", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    },
                })
                    .then(res => {
                    this.$toaster.success("Ваша заявка успешно отправлена!");
                    this.$store.state.openPartnersModal = false;
                })
                    .catch(err => {
                    this.$toaster.error("Проверьте заполненные поля!");
                });
            }
            else {
                this.$toaster.error("Заполните обязательные поля!");
            }
      },
        clearFormThis() {
          this.name = "",
          this.bin = "",
          this.number = "",
          this.email = "",
          this.searchText = "",
          this.typeReg = "",
          this.nameCompanyPerson = "",
          this.numberCompany = "",
          this.emailCompany = "",
          this.addressLegal = "",
          this.v$.$reset()
        }
    },
    components: { SelectComponentFilter, Treeselect }
};
</script>
<style lang="scss" scoped>
.selectBecomeRotate {
  rotate: 180deg;
}
.selectValue__valuesNone {
  display: flex !important;
  flex-direction: column;
  gap: 9px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 15px;
    margin: 3px 0 !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #252525;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb:hover {
    cursor: pointer !important;
    background: #555;
  }
}
.selectValue {
  position: relative;
  input {
    &:focus-within {
      .selectValue__valuesNone {
        display: none !important;
      }
    }
  }
  &__values {
    display: none;
    z-index: 2;
    top: 98%;
    position: absolute;
    color: black;
    border: 1px solid black;
    background: white;
    width: 100%;
    padding: 16px;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    &-city {
      p {
        cursor: pointer;
      }
    }
  }
}
.errorData {
  position: absolute;
  color: red !important;
  bottom: -17%;
  font-size: 10px !important;
}
.partnerReg {
  margin-top: 45px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    gap: 8px;
    flex-direction: column;
  }
  input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.7);
    height: 0;
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.1538))), 16px);
    padding: 6% 16px;
    &::placeholder {
      color: rgba(0, 0, 0, 0.33);
    }
  }
  select {
    appearance: none;
    width: 100%;
    border: 1px solid #000000;
    padding: 3% 16px;
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.1538))), 16px);
    
  }
  &__left {
    width: 43.73%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 992px) {
      width: 100%;
    }
    &__input {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 10px;
      p {
        color: #000000B2;
        font-weight: 400;
        font-size: min(
          max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1538))),
          14px
        );
      }
    }
    button {
      cursor: pointer;
      background: #000000;
      color: white;
      padding: 2% 16px;
      margin-top: 5.5%;
      font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.25))), 20px);
      transition: all 0.5s ease;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
.backPartner {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  color: black;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner {
  width: 62.81%;
  position: relative;
  background: #ffffff;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 94px 52px 82px;
  gap: 16px;
  margin: auto;
  @media (max-width: 992px) {
    padding: 32px 16px 32px 16px;
    width: 90%;
  }
  &__class {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: min(max(23px, calc(1.4375rem + ((1vw - 4px) * 0.7377))), 32px);
      font-weight: 600;
      line-height: 39px;
    }
    &-desc {
      margin: 48px 0;
      font-size: min(max(13px, calc(0.8125rem + ((1vw - 4px) * 0.2459))), 16px);
    }
    &-button {
      cursor: pointer;
      font-weight: 600;
      background: black;
      color: white;
      width: 100%;
      height: 56px;
      transition: all 0.5s ease;
      &:hover {
        background: rgb(28, 17, 17);
        transform: scale(1.01);
      }
    }
  }
}

.closeIcon {
  cursor: pointer;
  width: auto;
  position: absolute;
  right: 16px;
  top: 16px;
  transition: all 0.7s ease;
  &:hover {
    rotate: 180deg;
    transform: scale(1.1);
  }
}

.animated {
  animation-duration: 1s; /* длительность анимации */
  animation-fill-mode: both; /* сохраняем состояние после анимации */
}
.fadeIn {
  animation-name: fadeIn; /* имя анимации */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.selectBecome {
  transition: all 0.5s ease;
  position: absolute;
  width: 2%;
  top: 60%;
  right: 3%;
}
.vue-treeselect__control {
  height: 52px !important;
}
</style>