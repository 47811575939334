<template>
  <div class="news">
    <div class="content">
      <BreadCrumbs :items="[[this.$t('news'), '/news']]" />
      <div class="news__title">{{ this.$t("news") }}</div>
      <div class="news__items">
        <div class="news__item" v-for="item in paginatedNews" :key="item.id">
          <NewsCardVue
            :background="$cdn + item.image"
            :time="item.created_at.slice(0, 10)"
            :views="item.shows"
            :title="item.title"
            :discription="item.description"
            :link="item.id"
          ></NewsCardVue>
        </div>
      </div>
      <div class="awesCenter">
        <vue-awesome-paginate
          v-if="totalPages <= 8"
          :total-pages="totalPages"
          :limit="8"
          v-model="currentPage"
          :items-per-page="8"
          :total-items="(allNews.length).toFixed(1)"
          :disabled-class="'disabled'"
          :active-class="'active'"
          @page-change="pageChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>

import "vue-awesome-paginate/dist/style.css";
import BreadCrumbs from "@/components/UI/BreadCrumbs.vue";
import { mapActions, mapGetters } from "vuex";
import NewsCardVue from "../components/NewsCard.vue";
export default {
  data() {
    return {
      currentPage: 1
    };
  },
  mounted() {
    this.getNews();
  },
  methods: {
    ...mapActions(["getNews"]),
    pageChanged(page) {
      this.currentPage = page
    }
  },
  computed: {
    ...mapGetters(["allNews"]),
    paginatedNews() {
      const startIndex = (this.currentPage - 1) * 8;
      const endIndex = startIndex + 8;
      return this.allNews.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.allNews.length / 8);
    },
  },
  components: {
    NewsCardVue,
    BreadCrumbs,
  },
};
</script>

<style lang="scss">
.awesCenter {
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
}
</style>