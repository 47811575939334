<template>
  <div class="singleCategory" v-if="renderComponent">
    <div class="content">
      <div class="singleCategory__breadcrumbs">
        <span
          ><a href="/">{{ $t("main") }} </a></span
        >
        <span
          ><a href=""> • {{ $t("catalog") }} </a></span
        >
        <span
          ><a href=""> • {{ sub_category_info.title }} </a></span
        >
      </div>
      <div class="singleCategory__title">
        {{ sub_category_info.title }}
      </div>
      <div class="singleCategory__wrapper">
        <div class="singleCategory__menu">
          <div class="singleCategory__menu-title">{{ $t("category") }}</div>
          <div class="singleCategory__tool">
            <div class="singleCategory__tool-name">
              <div class="singleCategory__tool-icon">
                <img src="../assets/icons/home/arrow_left.png" alt="" />
              </div>
              <span>{{ category_info.title }}</span>
            </div>
            <div class="singleCategory__tool-items">
              <div
                class="singleCategory__tool-item"
                @click="
                  activeCategoryAndGetRequest(item, category_info.category)
                "
                v-for="item in category_info.sub_menu"
                :key="item.id"
                :class="{ active_category: active_category == item.id }"
              >
                {{ item.title }}
              </div>
            </div>
            <div class="singleCategory__more">{{ $t("showAll") }}</div>
          </div>
          <div class="singleCategory__type">
            <div class="singleCategory__type-title">Тип</div>
            <div class="singleCategory__type-items">
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Шлифмашина угловая</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель-шуруповерт</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Перфоратор</span>
              </div>
            </div>
            <div class="singleCategory__more">{{ $t("showAll") }}</div>
          </div>
          <div class="singleCategory__type">
            <div class="singleCategory__type-title">{{ $t("brand") }}</div>
            <div class="singleCategory__type-items">
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Шлифмашина угловая</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель-шуруповерт</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Дрель</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Перфоратор</span>
              </div>
            </div>
            <div class="singleCategory__more">{{ $t("showAll") }}</div>
          </div>
          <div class="singleCategory__cost">
            <div class="singleCategory__type-title">{{ $t("price") }}</div>
            <div class="singleCategory__cost-slider">
              <!-- <input type="range" v-modal.number="minPrice"> -->
              <!-- <input type="range" v-modal.number="maxPrice"> -->
            </div>
            <div class="singleCategory__cost-input">
              <input type="text" v-model="min_cost" />
              <input type="text" v-model="max_cost" />
            </div>

            <div class="singleCategory__additional">
              <div class="singleCategory__additional-title">
                Напряжение аккумулятора
              </div>
              <div class="singleCategory__type-items">
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
                <div class="singleCategory__type-item">
                  <input type="checkbox" />
                  <span>18 В</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="singleCategory__main">
          <div class="singleCategory__main-input">
            <select v-model="sort_select">
              <div class="singleCategory__main-input-sort">
                <img src="../assets/icons/components_icon/sort.svg" alt="" />
              </div>
              <optgroup>
                <option value="1">
                  {{ $t("ascendingOrder") }}
                </option>
                <option value="-1">
                  {{ $t("descendingOrder") }}
                </option>
              </optgroup>
            </select>
            <div
              class="singleCategory__main-input-filter"
              @click="filter = true"
            >
              <div class="singleCategory__main-input-icon">
                <img src="../assets/icons/mobile/filters.svg" alt="" />
              </div>
              Фильтр
            </div>

            <!-- mobile filter modal -->
            <div class="singleCategory__filter" v-show="filter">
              <div class="singleCategory__filter-header">
                <div class="singleCategory__filter-header-title">Фильтр</div>
                <div
                  class="singleCategory__filter-header-close"
                  @click="filter = false"
                >
                  <img src="../assets/icons/components_icon/close.svg" alt="" />
                </div>
              </div>
              <div class="singleCategory__filter-main">
                <div class="singleCategory__filter-categories">
                  <div
                    class="
                      singleCategory__filter-category
                      singleCategory__filter-category-cost
                    "
                  >
                    <p>{{ $t("price") }}</p>
                    <div class="singleCategory__filter-category-inputs">
                      <input type="number" />
                      <input type="number" />
                    </div>
                  </div>
                  <div
                    class="singleCategory__filter-category"
                    v-for="(item, i) in filter_items"
                    :key="i"
                  >
                    <div
                      class="singleCategory__filter-category-top"
                      @click="item.isOpen = !item.isOpen"
                    >
                      <p>{{ item.title }}</p>
                      <div
                        class="singleCategory__filter-category-arrow"
                        :class="{
                          'singleCategory__filter-category-arrow-reverse':
                            item.isOpen,
                        }"
                      >
                        <img
                          src="../assets/icons/home/arrow_right.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div v-if="item.isOpen">
                      <div
                        class="singleCategory__filter-category-bottom"
                        v-for="i in item.sub_titles"
                        :key="i.id"
                      >
                        <div
                          class="
                            singleCategory__filter-category-bottom-checkbox
                            basket__info-input
                          "
                          v-bind:class="{
                            filter_checkbox_checked: i.isChecked,
                          }"
                        >
                          <input
                            type="checkbox"
                            value="newsletter"
                            @click="i.isChecked = !i.isChecked"
                          />
                        </div>
                        <p>{{ i.title }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- mobile filter modal end! -->
          </div>
          <div class="singleCategory__main-items">
            <div
              class="singleCategory__main-item"
              v-for="item in cards"
              :key="item.id"
              v-bind:style="{
                order: item.price * sort_select,
              }"
            >
              <Card @add-basket-card="add_basket_card" :product="item"></Card>
            </div>
          </div>
          <div class="singleCategory__main-pagination">
            <div class="singleCategory__main-count">
              <span class="active_page">1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
            </div>
            <div class="singleCategory__main-icon">
              <img src="../assets/icons/components_icon/next_page.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Card from "../components/GoodsCard.vue";
export default {
  data() {
    return {
      sort_select: null,
      isCheckboxChecked: false,
      filter: false,
      active_category: 0,
      renderComponent: true,
      minPrice: 10,
      maxPrice: 1000,
      min_cost: "",
      max_cost: "",
      category_info: [],
      short_sub_menu: [],
      sub_category_info: [],
      cards: [
        {
          id: 0,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 1,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 2,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 3,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 4,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 5,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 6,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 7,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: 33490,
          count: 12,
          link: "product",
          fix_count: 12,
        },
      ], // get with api
      price: {
        min: 1400,
        max: 200000,
      },
      filter_items: [
        {
          id: 0,
          title: "Категории",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Сверление и завинчивание",
              isChecked: false,
            },
            {
              id: 1,
              title: "Перфораторы",
              isChecked: false,
            },
            {
              id: 2,
              title: "Шлифовальные и полировальные машины",
              isChecked: false,
            },
            {
              id: 3,
              title: "Пиление",
              isChecked: false,
            },
          ],
        },
        {
          id: 1,
          title: "Тип",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Сверление и завинчивание",
              isChecked: false,
            },
            {
              id: 1,
              title: "Перфораторы",
              isChecked: false,
            },
            {
              id: 2,
              title: "Шлифовальные и полировальные машины",
              isChecked: false,
            },
            {
              id: 3,
              title: "Пиление",
              isChecked: false,
            },
          ],
        },
        {
          id: 2,
          title: this.$t("brand"),
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Сверление и завинчивание",
              isChecked: false,
            },
            {
              id: 1,
              title: "Перфораторы",
              isChecked: false,
            },
            {
              id: 2,
              title: "Шлифовальные и полировальные машины",
              isChecked: false,
            },
            {
              id: 3,
              title: "Пиление",
              isChecked: false,
            },
          ],
        },
        {
          id: 3,
          title: "Напряжение аккумулятора",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Сверление и завинчивание",
              isChecked: false,
            },
            {
              id: 1,
              title: "Перфораторы",
              isChecked: false,
            },
            {
              id: 2,
              title: "Шлифовальные и полировальные машины",
              isChecked: false,
            },
            {
              id: 3,
              title: "Пиление",
              isChecked: false,
            },
          ],
        },
        {
          id: 4,
          title: "Мощность",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Сверление и завинчивание",
              isChecked: false,
            },
            {
              id: 1,
              title: "Перфораторы",
              isChecked: false,
            },
            {
              id: 2,
              title: "Шлифовальные и полировальные машины",
              isChecked: false,
            },
            {
              id: 3,
              title: "Пиление",
              isChecked: false,
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["STATE", "getIsAuth"]),
  },
  watch: {
    $route() {
      // console.log("route route", this.$route);
      this.render();
    },
  },
  async created() {
    await this.render();
  },

  methods: {
    ...mapActions(["GET_PRODUCT_LIST"]),
    ...mapMutations(["SET_SUB_CATEGORY"]),
    async render() {
      this.renderComponent = false;
      window.scrollTo(0, 0);
      await this.GET_PRODUCT_LIST();
      await this.set_category();
      await this.setProducts();
      await this.set_sub_category(this.$route.params.id);
      this.renderComponent = true;
    },
    setProducts() {
      this.cards = this.$store.state.product;
    },
    activeCategoryAndGetRequest(item, category) {
      let id = item.id;
      // console.log("item !!! item", item);
      this.$router.push("/single-category/" + category + "/" + id);
      this.set_sub_category(this.$route.params.id);
      this.SET_SUB_CATEGORY(item);
      this.active_category = id;
      // console.log("id", id, category);
    },
    async set_category() {
      this.category_info = this.STATE.categories;
      // console.log("set_category", this.category_info);
    },
    async set_sub_category(id) {
      this.sub_category_info = this.STATE.subCategory;
      this.active_category = this.sub_category_info.id;
      // console.log("set_sub_categoryset_sub_category", id);
    },
    add_basket_card() {
      // console.log("123");
    },
  },
  components: {
    Card,
  },
};
</script>

<style lang="scss">
.filter_checkbox_checked {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 18px;
  width: 18px;
  background: url("@/assets/icons/basket/check.svg");
}
</style>