<template>
  <div class="full">
    <div class="content">
      <BreadCrumbs
        :items="[
          [this.$t('news'), '/news'],
          [items.title, $route.fullPath],
        ]"
      />
      <div class="full__wrapper">
        <div class="full__content">
          <div class="full__title">
            {{ items.title }}
          </div>
          <div class="full__info">
            <div class="full__item">
              <div class="full__item-img">
                <img src="../assets/icons/news_icon_time.svg" alt="" />
              </div>
              <div class="full__item-text">
                {{ dateTime(items.created_at) }}
                <!-- {{setShortTime(items.data.created_at)}} -->
              </div>
            </div>
            <div class="full__item">
              <div class="full__item-img">
                <img src="../assets/icons/news_icon_view.svg" alt="" />
              </div>
              <div class="full__item-text">
                {{ items.shows }}
              </div>
            </div>
          </div>
          <div class="full__block">
            <div class="full__text" v-html="items.description"></div>
            <div class="full__bg">
              <img :src="$cdn + items.image" alt="" />
            </div>
          </div>
        </div>
        <div class="full__title">{{ $t("relatedNews") }}</div>
        <div class="newsSiwper">
          <swiper
                class="newsSiwper2"
                :allowTouchMove="true"
                :lazy="true"
                :loop="false"
                :breakpoints="swiper.breakpoint1"
              >
            <swiper-slide
              class="newsSiwper3"
              v-for="item in allNews"
              :key="item"
            >
              <NewsCard 
              class="newsSiwper4"
              :background="$cdn + item.image"
              :views="item.shows"
              :title="item.title"
              :discription="item.description"
              :link="item.id"/>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide,} from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; 

import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination } from 'swiper';
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay])

import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import BreadCrumbs from "@/components/UI/BreadCrumbs.vue";
import NewsCard from "@/components/NewsCard.vue";
export default {
  data() {
    return {
      items: [],
      swiper: {
        breakpoint1: {
          768: {
            slidesPerView: 2
          },
          1440: {
            slidesPerView: 4
          }
        }
      }
    };
  },
  async created() {
    this.getNewsById();
  },
  watch: {
    $route() {
      this.getNewsById();
    },
  },
  mounted() {
    this.getNews();
  },
  methods: {
    ...mapActions(["getNews"]),
    dateTime(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    async getNewsById() {
      await this.$axios
        .get(`V1/news-by-id?lang=ru&news_id=${this.$route.params.id}`)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((e) => {
          // console.log(e);
        });
    },
  },
  computed: {
    ...mapGetters(["ID_INFO", "allNews"]),
    createdTime(time) {
      return time.splice(0, 10);
    },
  },
  components: { BreadCrumbs, NewsCard, Swiper, SwiperSlide  },
};
</script>

<style lang="scss" scoped>
.newsSiwper {
  width: 100%;
}
.newsSiwper2 {
  // width: 100%;
  padding: 50px 12px;
}
.newsSiwper3 {
  width: auto;
  height: auto;
}
.newsSiwper4 {
  margin-right: 30px;
}
div,
p {
  // text-align: justify;
}
.full {
  padding-top: 59px;
  &__wrapper {
    margin-top: 32px;
  }
  &__block {
    display: flex;
    align-items: flex-start;
    gap: 2%;
    margin-top: 40px;
  }
  @media screen and (max-width: 991px) {
    &__block {
      flex-direction: column;
    }
    &__bg {
      order: 1;
      width: 100% !important;
    }
    &__text {
      order: 2;
      width: 100% !important;
    }
  }
  &__info {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 23px;
  }
  &__item {
    align-items: center;
    &-img {
      display: flex;
      align-items: center;
    }
  }
  &__title {
    font-size: 32px;
  }
  &__text,
  &__bg {
    width: 49%;
    margin-top: unset;
  }
}

.news__items {
  width: 100%;
}
</style>