<template>
  <div class="dropdown">
    <div
      class="dropdown-toggle"
      :class="{ 'dropdown-placeholder': selectedValue === null }"
      @click.stop="toggleDropdown"
    >
      {{ selectedOption }}
      <img
        :class="{ 'dropdown-toggle-arrow': isOpen }"
        :src="require('@/assets/icons/arrow.svg')"
        alt="right11"
      />
    </div>
    <div v-if="isOpen" class="dropdown-menu" :id="name">
      <div
        v-for="option in options"
        :key="option.value"
        :class="{
          'dropdown-menu-active':
            option.value === currentValue || selectedValue === option.value,
        }"
        @click="selectOption(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select an option",
    },
    name: {
      type: String,
      required: false,
    },
    defaultOption: {
      type: Object,
      required: false,
      default: () => ({ value: null, label: "Select an option" }),
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  data() {
    return {
      selectedOption:
        this.defaultOption.value === null
          ? this.placeholder
          : this.defaultOption.label,
      selectedValue: this.defaultOption.value,
      isOpen: false,
      currentValue: null,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      document.addEventListener("click", this.handleClickOutside);
    },
    handleClickOutside(event) {
      if (
        this.isOpen &&
        !document.getElementById(this.name).contains(event.target)
      ) {
        this.isOpen = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    selectOption(option) {
      this.selectedOption = option.label;
      this.selectedValue = option.value,
      this.currentValue = option.value;
      this.isOpen = false;
      this.$emit("selected", option);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  &-placeholder {
    font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.2308))), 14px);
    color: #828282;
  }
  &-toggle {
    background: #FFFFFF;
    border: 1px solid #E3E5E6;
    font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.2308))), 14px);
    cursor: pointer;
    padding: 0px 15px;
    height: 38px;
    // border: 1px solid #ccc;
    /* border-radius: 5px; */
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    img {
      width: 13px;
      transform: rotate(0);
      transition: transform 0.2s;
    }
    &-arrow {
      transform: rotate(180deg) !important;
      transition: transform 0.2s !important;
    }
  }
  &-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: -webkit-fill-available;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
    div {
      padding: 10px;
      cursor: pointer;
      &:hover {
        background-color: #f2f2f2;
      }
    }
    &-active {
      background-color: #f2f2f2;
    }
  }
}
.dropdown-menu {
    font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.2308))), 14px);
}
</style>