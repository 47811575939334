<template>
  <div class="singleCategory__breadcrumbs">
    <span
      ><router-link class="blackText" to="/"
        >{{ $t("main") }}
      </router-link></span
    >
    <template v-for="(item, i) in items" :key="i">
      <template v-if="item != null">
        <div>•</div>
        <span
          ><router-link :to="`${item[1]}`"> {{ item[0] }} </router-link></span
        >
      </template>
    </template>
  </div>
</template>
<script>
export default {
  name: "TabsUI",
  props: ["items"],
  data() {
    return {
      activeItem: 0,
    };
  },
};
</script>
<style lang="scss" scoped>
/* .bredcrumbs {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  color: #6b6a6a;
  margin-bottom: 40px;
  @media (max-width: 992px) {
    gap: 5px;
    font-size: 14px;
    margin-bottom: 30px;
  }
} */
p {
  cursor: pointer;
}
</style>