<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import MainLayout from "@/views/layout/main";
import AuthLayout from "@/views/layout/auth";
import OrderLayout from "@/views/layout/order";
import AccLayout from "@/views/layout/acc";
export default {
  name: "App",
  components: { MainLayout, AuthLayout, OrderLayout, AccLayout },
  data: () => ({}),
  computed: {
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },
  watch: {
    $route() {      
      document.querySelector('.catalog-mobile')?.classList?.remove('catalog-mobile-animation')
      document.querySelector('.catalog-mobile')?.classList?.add('catalog-mobile-animation-hide')
        document.body.style.overflowY = 'auto'
        document.documentElement.style.overflowY = 'auto'
    }
  }
};
</script>



<style lang="scss">

#app {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #252525;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.overflowybody {
  overflow-y: hidden !important;
}
</style>

