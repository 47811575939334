<template>
    <div>
        <div class="content">
            <div class="personal-data">
                <h2 class="personal-data__title">Согласие на обработку персональных данных</h2>
                <div class="personal-data__text"> 1. ОБЩИЕ ПОЛОЖЕНИЯ
                    Путём нажатия на сайте кнопки «Согласен» (-а) ,
                    Вы предоставляете ТОО «GES» (Республика Казахстан, город
                    Алматы,
                    A26F8D4, проспект Нурсултан Назарбаев, 240Г) (далее – «Компания») свое согласие на сбор и обработку
                    Ваших персональных данных в соответствии со статьей 8 Закона Республики Казахстан от 21 мая 2013
                    года №
                    94-V «О персональных данных и их защите» на протяжении срока, определенного Компанией для выполнения
                    нижеуказанных целей на следующих условиях:
                    <br />1. Согласие дается на сбор и обработку следующих персональных данных:
                    <br />- ИИН;
                    <br />2. Переданные Вами персональные данные, а также аудиофайлы, полученные в результате проведения
                    аудиозаписей, могут обрабатываться Компанией (сбор, запись, систематизация, накопление, хранение,
                    изменение, дополнение, использование, распространение, обезличивание, блокирование и уничтожение
                    персональных данных, включая распространение персональных данных и трансграничную передачу
                    персональных
                    данных, как с применением средств автоматизации, так и без использования таких средств), в следующих
                    целях:
                    <br />- формирования информационной базы данных потребителей продукции Компании;
                    <br />- предоставления информации о товарах и услугах Компании, а также других компаний, которые
                    входят
                    в группу «Бритиш Американ Тобакко»;
                    <br />- проведения опросов;
                    <br />- информирования об активностях, программах и деятельности Компании; регистрации/авторизации
                    на
                    официальных сайтах Компании и сайтах о продукции, предоставления доступа на веб-сайты, страницы
                    социальных сетей или мобильные приложения Компании, а также других компаний, которые входят в группу
                    «GES», в том числе <a href="">GES.kz</a>
                    <br />3. Персональные данные не являются общедоступными.
                    <br />4. Основанием для обработки персональных данных является п.1 ст.7 Закона Республики Казахстан
                    №
                    94-V ЗРК от 21 мая 2013 года «О персональных данных и их защите».
                    <br />5. В ходе обработки с персональными данными могут быть совершены следующие действия:
                    накопление,
                    хранение, изменение, дополнение, использование, распространение, обезличивание, блокирование и
                    уничтожение, запись, систематизация. Кроме того, настоящим дается согласие на поручение обработки
                    персональных данных третьим лицам для достижения целей, указанных в п.2 настоящего Согласия.
                    <br />6. Согласие дается также на передачу персональных данных юридическим лицам, входящим в группу
                    «Бритиш Американ Тобакко», а также третьим лицам, оказывающим услуги юридическим лицам, входящим в
                    состав группы «Бритиш Американ Тобакко» для достижения целей, указанных в п. 1 выше..
                    <br />7. Вы соглашаетесь передавать Ваши персональные данные за пределы территории Республики
                    Казахстан
                    и обрабатывать их вне Республики Казахстан (трансграничная передача), раскрывать и передавать Ваши
                    персональные данные связанным лицам Компании, подрядчикам и субподрядчикам Компании.
                    <br />8. Вы соглашаетесь по запросу Компании предоставлять информацию и копии документов в целях
                    проверки вашего возраста и подтверждения достижения возраста 21 года.
                    <br />9. Компания вправе обрабатывать полученные персональные данные при условии обеспечения их
                    конфиденциальности и безопасности. Принципы и условия обработки информации изложены в Политике в
                    отношении обработки персональных данных.
                    <br />10. Вы вправе в любой момент отозвать данное согласие, а также дополнить или изменить свои
                    персональные данные в любое время путем отправки запроса на электронную почту Компании:
                    info@myges.kz
                    или отправив письменный запрос по адресу: A26F8D4, Республика Казахстан, г. Алматы, пр. Нурсултан
                    Назарбаев, 240Г с указанием идентифицирующих Вас персональных данных, указанных Вами при регистрации
                    на
                    сайте Компании.
                    <br />11. Настоящим Вы подтверждаете, что предоставленные Вами данные являются достоверными.
                    <br />12. Настоящее согласие действует все время до момента прекращения обработки персональных
                    данных.
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    created() {
    }
}
</script>

<style lang="scss" >
.personal-data {
    padding: 40px;

    &__title {
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        color: #282828;
    }

    &__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        width: 90%;
        text-align: left;
        margin: 0 auto;
        margin-top: 40px;
        color: #282828;

        @media (max-width: 991px) {
            width: 100%;
        }
    }
}
</style>