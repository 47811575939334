<template>
    <div class="error">
        <div class="error__bg">
            <img src="../assets/img/error_bg.png" alt="">
        </div>
        <div class="error__title">
            Упс!
        </div>
        <div class="error__subtitle">
            Страница не найдена
        </div>
        <button class="button button_error" @click="$router.push('/')">
            <div class="error__icon">
                <img src="../assets/icons/auth/arrow_back_white.svg" alt="">
            </div>
            <div>Вернуться на главную страницу</div>
        </button>
    </div>
</template>
 
<style lang="scss">

</style>