<template>
  <div class="about">
    <div class="content">
      <!-- <BreadCrumbs :items="[[about.title, '/about']]" /> -->
      <div class="bread"> 
          <router-link to="/" style="color: black"><p>{{ $t('main') }}</p></router-link>
         <div style="opacity: 0.3;">•</div>
         <p style="opacity: 0.7;">{{ $t('aboutUs') }}</p>
      </div>

      <section class="about__welcome">
        <div class="about__title">{{about.title}}</div>
        <div class="about__subtitle">
          {{about.subtitle}}
        </div>
      </section>
      <section class="about__info">
        <div class="about__discription" v-html="about.description">
        </div>
        <div class="about__picture">
          <img :src="$cdn + about.image" alt="" />
        </div>
      </section>

      <section class="about__how">
        <div class="about__section-title">{{ $t("howWork") }}</div>
        <div class="about__cards">
          <div class="about__card">
            <div class="about__card-img">
              <img src="../assets/img/about_card-1.png" alt="" />
            </div>
            <div class="about__card-text">
              <span>Мы ежедневно обновляем предложения</span>
              <p>Акции и промо от поставщиков по разным категориях</p>
            </div>
          </div>
          <div class="about__card">
            <div class="about__card-img">
              <img src="../assets/img/about_card-2.png" alt="" />
            </div>
            <div class="about__card-text">
              <span>Мы ежедневно обновляем предложения</span>
              <p>Акции и промо от поставщиков по разным категориях</p>
            </div>
          </div>
          <div class="about__card">
            <div class="about__card-img">
              <img src="../assets/img/about_card-3.png" alt="" />
            </div>
            <div class="about__card-text">
              <span>Мы ежедневно обновляем предложения</span>
              <p>Акции и промо от поставщиков по разным категориях</p>
            </div>
          </div>
        </div>
      </section>

      <section class="home__brands">
        <div class="about__section-title">{{ $t("shop") }}</div>
        <div class="home__slider">
          <div class="home__brands-swiper">
            <swiper
              class="home__brands-item"
              :breakpoints="breakpoints"
              :lazy="true"
              :navigation="videosNavigation"
              :loop="true"
              :autoplay="autoplay"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
              <swiper-slide v-for="item in brands" :key="item.id">
                <img :src="item.img" alt="Img" />
              </swiper-slide>
            </swiper>
            <div class="home__brands-left">
              <img src="../assets/icons/arrow_left.png" alt="Arrow" />
            </div>
            <div class="home__brands-right">
              <img src="../assets/icons/arrow_right.png" alt="Arrow" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
// import { Swiper, SwiperSlide } from 'swiper/vue';

import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import BreadCrumbs from "@/components/UI/BreadCrumbs.vue";

export default {
  data() {
    return {
      about: null,
      brands: [
        {
          id: 0,
          img: require("../assets/img/home/brand.png"),
        },
        {
          id: 1,
          img: require("../assets/img/home/brand.png"),
        },
        {
          id: 2,
          img: require("../assets/img/home/brand.png"),
        },
        {
          id: 3,
          img: require("../assets/img/home/brand.png"),
        },
      ],

      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        991: {
          slidesPerView: 8,
        },
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    };
  },
  created() {
    window.scroll(0, 0);
    this.$axios.get('https://admin-ges.test-vip.kz/api/get-pages?lang=ru&slug=about')
      .then(res => {
        this.about = res.data.pages
      })
      .catch(err => {
        // console.log(err);
      })
  },
  methods: {
    onSwiper(swiper) {
      // console.log(swiper);
    },
    onSlideChange() {
      // console.log("slide change");
    },
  },
  components: { Swiper, SwiperSlide, BreadCrumbs },
  // components: { Card, },
};
</script>

<style lang="scss">
.bread {
  display: flex;
  gap: 5px;
}

.about__welcome {
  background-image: url("@/assets/img/bg/bg.png");
  background-position: center;
  background-size: cover;
}
.home__slider {
  margin-top: 40px;
  position: relative;
  @media screen and (max-width: 576px) {
    margin-top: 16px;
  }
}

.about__picture {
  width: 40.93%;
  img {
    width: 100%;
    height: 100%;
  }
}
.about__discription {
  margin: unset !important;
  padding: 0 !important;
  font-size: revert !important;
  h1 {
    margin-bottom: 24px;  
    font-size: revert !important;
    font: revert !important;
    color: black;
  }
  p {
    font-size: revert !important;
    font: revert !important;
  }
}
.about__card {
  width: 32% !important;
}
</style>