<template>
  <div class="singleShop" v-if="renderComponent">
    <div class="content">
      <div class="singleCategory__breadcrumbs">
        <span
          ><a href="">{{ $t("main") }}</a></span
        >
        <span><a href=""> • Магазин</a></span>
        <span
          ><a href=""> • Каталог поставщика {{ shop_info.name }}</a></span
        >
      </div>
      <div class="singleShop__header">
        <div class="singleShop__title">
          Каталог поставщика {{ shop_info.name }}
        </div>
        <div class="singleShop__link">
          <div class="singleShop__header-icon">
            <img src="../assets/icons/card/like.svg" alt="" />
          </div>
          <span>{{ $t("toFavourites") }}</span>
        </div>
        <div class="singleShop__link">
          <div class="singleShop__header-icon">
            <img src="../assets/icons/card/share.svg" alt="" />
          </div>
          <span>{{ $t("toShare") }}</span>
        </div>
      </div>
      <div class="singleShop__info">
        <StarsRating :rating="shop_info.rating"></StarsRating>
        <div class="singleShop__info__item">
          <div class="singleShop__info__icon">
            <img src="../assets/icons/card/bill.svg" alt="" />
          </div>
          <div class="singleShop__info__text">
            {{ $t("minDelivery") }} {{ shop_info.minAmount }} ₸
          </div>
        </div>
        <div class="singleShop__info__item">
          <div class="singleShop__info__icon">
            <img src="../assets/icons/basket/car.svg" alt="" />
          </div>
          <div class="singleShop__info__text">
            {{ this.$t("delivery") }} - {{ shop_info.deliveryTime }},
            {{ shop_info.deliveryCost }}
          </div>
        </div>
      </div>
      <div class="market-slide">
        <swiper
          class="market-swiper"
          :breakpoints="breakpoints"
          :spaceBetween="13"
          :lazy="true"
          :navigation="shopNavigation"
          :loop="false"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="item in market_category" :key="item.id">
            <div class="market__category">
              <div class="market__category-item">
                <div class="market__category-img">
                  <img :src="item.img" alt="" />
                </div>
                <div class="market__category-name">
                  {{ item.title }}
                </div>
                <div class="market__category-count">
                  {{ item.count }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="market-swiper-left">
          <img src="../assets/icons/arrow_left.png" alt="Arrow" />
        </div>
        <div class="market-swiper-right">
          <img src="../assets/icons/arrow_right.png" alt="Arrow" />
        </div>
      </div>
      <div class="singleShop__main">
        <div class="singleShop__menu">
          <div class="singleShop__clear">
            <div class="singleShop__clear-icon">
              <img src="../assets/icons/components_icon/close.svg" alt="" />
            </div>
            <span>Очистить фильтры</span>
          </div>

          <div class="singleCategory__type">
            <div class="singleCategory__type-title">{{ $t("brand") }}</div>
            <div class="singleCategory__type-items">
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Мегастрой</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Мегастрой</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Мегастрой</span>
              </div>
              <div class="singleCategory__type-item">
                <input type="checkbox" />
                <span>Мегастрой</span>
              </div>
            </div>
            <div class="singleCategory__more">{{ $t("showAll") }}</div>
          </div>
        </div>
        <div class="singleCategory__main">
          <div class="singleCategory__main-input">
            <select>
              <div class="singleCategory__main-input-sort">
                <img src="../assets/icons/components_icon/sort.svg" alt="" />
              </div>
              <optgroup>
                <option :value="$t('ascendingOrder')">
                  {{ $t("ascendingOrder") }}
                </option>
                <option :value="$t('descendingOrder')">
                  {{ $t("descendingOrder") }}
                </option>
              </optgroup>
            </select>
            <div
              class="singleCategory__main-input-filter"
              @click="filter = true"
            >
              <div class="singleCategory__main-input-icon">
                <img src="../assets/icons/mobile/filters.svg" alt="" />
              </div>
              Фильтр
            </div>

            <!-- mobile filter modal -->
            <div class="singleCategory__filter" v-show="filter">
              <div class="singleCategory__filter-header">
                <div class="singleCategory__filter-header-title">Фильтр</div>
                <div
                  class="singleCategory__filter-header-close"
                  @click="filter = false"
                >
                  <img src="../assets/icons/components_icon/close.svg" alt="" />
                </div>
              </div>
              <div class="singleCategory__filter-main">
                <div class="singleCategory__filter-categories">
                  <div
                    class="
                      singleCategory__filter-category
                      singleCategory__filter-category-cost
                    "
                  >
                    <p>{{ $t("price") }}</p>
                    <div class="singleCategory__filter-category-inputs">
                      <input type="number" />
                      <input type="number" />
                    </div>
                  </div>
                  <div
                    class="singleCategory__filter-category"
                    v-for="(item, i) in filter_items"
                    :key="i"
                  >
                    <div
                      class="singleCategory__filter-category-top"
                      @click="item.isOpen = !item.isOpen"
                    >
                      <p>{{ item.title }}</p>
                      <div
                        class="singleCategory__filter-category-arrow"
                        :class="{
                          'singleCategory__filter-category-arrow-reverse':
                            item.isOpen,
                        }"
                      >
                        <img
                          src="../assets/icons/home/arrow_right.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div v-if="item.isOpen">
                      <div
                        class="singleCategory__filter-category-bottom"
                        v-for="i in item.sub_titles"
                        :key="i.id"
                      >
                        <div
                          class="
                            singleCategory__filter-category-bottom-checkbox
                            basket__info-input
                          "
                          v-bind:class="{
                            filter_checkbox_checked: i.isChecked,
                          }"
                        >
                          <input
                            type="checkbox"
                            value="newsletter"
                            @click="i.isChecked = !i.isChecked"
                          />
                        </div>
                        <p>{{ i.title }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- mobile filter modal end! -->
          </div>
          <div class="singleCategory__main-items">
            <div
              class="singleCategory__main-item"
              v-for="item in cards"
              :key="item.id"
            >
              <Card @add-basket-card="add_basket_card" :product="item"></Card>
            </div>
          </div>
          <div class="singleCategory__main-pagination">
            <div class="singleCategory__main-count">
              <span class="active_page">1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
            </div>
            <div class="singleCategory__main-icon">
              <img src="../assets/icons/components_icon/next_page.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import StarsRating from "../components/StarsRating.vue";
import Card from "../components/GoodsCard.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isCheckboxChecked: false,
      filter: false,
      renderComponent: true,
      filter_items: [
        {
          id: 0,
          title: "Категории",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Сверление и завинчивание",
              isChecked: false,
            },
            {
              id: 1,
              title: "Перфораторы",
              isChecked: false,
            },
            {
              id: 2,
              title: "Шлифовальные и полировальные машины",
              isChecked: false,
            },
            {
              id: 3,
              title: "Пиление",
              isChecked: false,
            },
          ],
        },
        {
          id: 1,
          title: "Тип",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Сверление и завинчивание",
              isChecked: false,
            },
            {
              id: 1,
              title: "Перфораторы",
              isChecked: false,
            },
            {
              id: 2,
              title: "Шлифовальные и полировальные машины",
              isChecked: false,
            },
            {
              id: 3,
              title: "Пиление",
              isChecked: false,
            },
          ],
        },
        {
          id: 2,
          title: this.$t("brand"),
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Сверление и завинчивание",
              isChecked: false,
            },
            {
              id: 1,
              title: "Перфораторы",
              isChecked: false,
            },
            {
              id: 2,
              title: "Шлифовальные и полировальные машины",
              isChecked: false,
            },
            {
              id: 3,
              title: "Пиление",
              isChecked: false,
            },
          ],
        },
        {
          id: 3,
          title: "Напряжение аккумулятора",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Сверление и завинчивание",
              isChecked: false,
            },
            {
              id: 1,
              title: "Перфораторы",
              isChecked: false,
            },
            {
              id: 2,
              title: "Шлифовальные и полировальные машины",
              isChecked: false,
            },
            {
              id: 3,
              title: "Пиление",
              isChecked: false,
            },
          ],
        },
        {
          id: 4,
          title: "Мощность",
          isChecked: false,
          sub_titles: [
            {
              id: 0,
              title: "Сверление и завинчивание",
              isChecked: false,
            },
            {
              id: 1,
              title: "Перфораторы",
              isChecked: false,
            },
            {
              id: 2,
              title: "Шлифовальные и полировальные машины",
              isChecked: false,
            },
            {
              id: 3,
              title: "Пиление",
              isChecked: false,
            },
          ],
        },
      ],
      cards: [
        {
          id: 0,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 1,
          image: require("../assets/img/good3.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 2,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 3,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 4,
          image: require("../assets/img/good3.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 5,
          image: require("../assets/img/good3.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 6,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
        {
          id: 7,
          image: require("../assets/img/good2.png"),
          sale_persents: "30",
          discription: "Аккумуляторная дрель-шуруповерт Makita DF330DWE...",
          price: 19390,
          old_price: "33 490 ₸",
          count: 12,
          link: "product",
          fix_count: 12,
        },
      ],
      breakpoints: {
        330: {
          slidesPerView: 2,
        },
        576: {
          slidesPerView: 3,
        },
        991: {
          slidesPerView: 8,
        },
      },
      shopNavigation: {
        prevEl: ".market-swiper-left",
        nextEl: ".market-swiper-right",
      },
      market_category: [
        {
          id: 0,
          img: require("../assets/icons/market/item_1.png"),
          title: this.$t("favouritesProducts"),
        },
        {
          id: 1,
          img: require("../assets/icons/market/item_2.png"),
          title: "Строительные материалы",
          count: "5083",
        },
        {
          id: 2,
          img: require("../assets/icons/market/item_3.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 3,
          img: require("../assets/icons/market/item_4.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 4,
          img: require("../assets/icons/market/item_5.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 5,
          img: require("../assets/icons/market/item_6.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 6,
          img: require("../assets/icons/market/item_7.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 7,
          img: require("../assets/icons/market/item_8.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
        {
          id: 8,
          img: require("../assets/icons/market/item_9.png"),
          title: "Электроинстру-менты",
          count: "5083",
        },
      ],
      shop_info: [],
    };
  },
  async created() {
    await this.render();
  },
  computed: {
    ...mapGetters(["STATE"]),
  },
  watch: {
    $route() {
      this.render();
      // console.log(this.$route, "asdasdd");
    },
  },
  methods: {
    render() {
      this.renderComponent = false;
      window.scrollTo(0, 0);
      this.setShopInfo();
      this.renderComponent = true;
    },
    setShopInfo() {
      this.shop_info = this.STATE.singleMarketShop;
    },
    add_basket_card() {
      // console.log("asdasd");
    },
    onSwiper(swiper) {
      // console.log(swiper);
    },
    onSlideChange() {
      // console.log("slide change");
    },
  },
  components: {
    Card,
    StarsRating,
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss" scoped>
.swiperDE {
  display: flex;
  gap: 13px;
}
</style>