<template>
  <div class="authorization" @click.stop="$store.state.openAuthRegister = false">
    <div class="content">
      <div class="authorization-enter" @click.stop>
        <div class="authorization-enter__wrapper">
          <h2 class="authorization__title">Введите новый пароль</h2>

          <div class="authorization-enter__items">
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">Новый пароль</div>
              <div
                class="authorization-enter__input authorization-enter__password"
              >
                <div>
                  <input
                    :type="showPass ? 'password' : 'text'"
                    v-model="password.password"
                    placeholder="Введите пароль"
                    autocomplete="nope"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                  />
                  <span class="eye" @click="showPass = !showPass">
                    <img
                      :src="
                        require(`@/assets/icons/${
                          !showPass ? 'eyeshow' : 'eyehide'
                        }.png`)
                      "
                    />
                  </span>
                </div>
              </div>
              <template
                v-for="(error, index) of v$.password.password.$errors"
                :key="index"
              >
                <span style="font-size: 12px; color: red">{{
                  error.$message
                }}</span
                ><br />
              </template>
            </div>

            <div class="authorization-enter__item">
              <div class="authorization-enter__name">Повторите пароль</div>
              <div
                class="authorization-enter__input authorization-enter__password"
              >
                <div>
                  <input
                    :type="showPass ? 'password' : 'text'"
                    v-model="password.confirm"
                    placeholder="Введите пароль еще раз"
                    autocomplete="nope"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                  />
                  <span class="eye" @click="showPass = !showPass">
                    <img
                      :src="
                        require(`@/assets/icons/${
                          !showPass ? 'eyeshow' : 'eyehide'
                        }.png`)
                      "
                    />
                  </span>
                </div>
              </div>
              <template
                v-for="(error, index) of v$.password.confirm.$errors"
                :key="index"
              >
                <span style="font-size: 12px; color: red">{{
                  error.$message
                }}</span
                ><br />
              </template>
            </div>
          </div>
          <button class="button button_auth" @click="changePassword">
            Сбросить пароль
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
// import { IMaskDirective } from 'vue-imask';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });
import axios from "axios";
export default {
  data() {
    return {
      v$: useVuelidate(),
      password: {
        password: "",
        confirm: "",
      },
      showCodeForm: false,
      checked: false,
      showPass: true,
    };
  },
  computed: {
    formValid() {
      return this.v$.$invalid;
    },
  },
  validations() {
    // const mustBeCorrectPhoneNumber = (value) => value.include('+7')
    return {
      password: {
        password: { required, minLength: minLength(8) },
        confirm: { required, sameAs: sameAs(this.password.password) },
      },
    };
  },

  methods: {
    changePassword() {
      this.v$.$validate();
      if (!this.v$.$invalid) {
        axios
          .post(
            "V1/reset-password",
            {
              new_password: this.password,
              new_password_confirmation: this.confirm,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          )
          .then((response) => {
            this.$router.push("/acc/profile");
          })
          .catch((errors) => {
            // console.log(errors);
          });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
</style>