<template>
  <div class="product" v-if="productInfo">
    <BuyOneClick :productId="productInfo.id"/>
    <Modal v-show="isModal" @open-modal="isModal = false"></Modal>
    <div class="content contentProduct" v-if="crumbs.length">
      <BreadCrumbs
        :items="[
          [$t('catalog'), '/catalog'],
          [crumbs[1].title, '/catalog/' + crumbs[1].slug],
          [crumbs[0].title, '/catalog/'+ crumbs[1].slug + '/' + crumbs[0].slug],
          [productInfo.title, `/product/${productInfo.slug}`],
        ]"
      />
      <h2 class="desctop product__name" v-html="productInfo.title" />
      <div class="product__info desctop">
        <div class="product__info-good">
          <div class="product__info-code">
            <span style="color: #626262">{{ $t("productCode") }}</span>
            <span>{{ productInfo.artikul }}</span>
          </div>

          <div class="product__info-rating">
            <StarsRating :rating="raiting"></StarsRating>
          </div>

          <div class="product__info-feedback">
            <span @click="scrollTo('navs'), count = 1">
              {{ reviews.length ? reviews.length : "0" }} {{ $t("reviews") }}
            </span>
            <div class="devider"></div>
            <span @click="scrollTo('navs'), count = 2">
              {{ questions.length ? questions : "0" }} {{ $t("question") }}
            </span>
          </div>
        </div>

        <div class="product__info-links">
          <div class="product__info-link">
            <div
              class="product__info-icon product__info-icon-like"
              @click="getIsAuth ? addProductToFavorite(productInfo.id) : notWishlist()"
              :class="{ product__info_empty: isLiked && getIsAuth}"
            ></div>

            <div class="product__info-text">{{ $t("toFavourites") }}</div>
          </div>
          <div class="product__info-link">
            <div class="product__info-icon">
              <img src="../assets/icons/share.svg" alt="" />
            </div>
            <div class="product__info-text">{{ $t("toShare") }}</div>
          </div>
        </div>
      </div>
      <div class="product__good">
        <ThumbsSwiper
          :mainImage="mainImg"
          :productImages="imagesPP"
        ></ThumbsSwiper>

        <div class="product__info-links mobile">
          <div class="product__info-link">
            <div
              class="product__info-icon product__info-icon-like"
              @click="getIsAuth ? addProductToFavorite(productInfo.id) : notWishlist()"
              :class="{ product__info_empty: isLiked && getIsAuth}"
            ></div>

            <div class="product__info-text">{{ $t("toFavourites") }}</div>
          </div>
          <div class="product__info-link">
            <div class="product__info-icon">
              <img src="../assets/icons/share.svg" alt="" />
            </div>
            <div class="product__info-text">{{ $t("toShare") }}</div>
          </div>
        </div>
        <!-- <div class="product__picture">
          <div class="product__picture-big">
            <img :src="$cdn + product.main_image" alt="" />
          </div>
          <div class="product__picture-items">
            <div
              class="product__picture-item"
              v-for="item in product.images"
              :key="item.id"
              @click="bigPic = item.img"
            >
              <img :src="$cdn + item" alt="" />
            </div>
          </div>
        </div> -->
        <div class="desctop prodTop">
          <div class="product__about" v-if="productInfo.features.length">
            <div
              class="product__about-item"
              v-for="item in productInfo.features[0].values"
              :key="item.id"
            >
              <div class="product__about-info" v-if="item.type !== null">
                {{ item.title }}
              </div>
              <div class="product__about-dots"></div>
              <div
                class="product__about-info justInfo"
                v-if="item.type !== null"
              >
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="mobile">
          <h2
            class="product__name"
            style="margin-top: 24px"
            v-html="productInfo.title"
          />
          <div class="product__info">
            <div class="product__info-good">
              <div class="product__info-code">
                <span style="color: #626262">{{ $t("productCode") }}</span>
                <span>{{ productInfo.artikul }}</span>
              </div>

              <div class="product__info-rating">
                <StarsRating :rating="raiting"></StarsRating>
              </div>

              <div class="product__info-feedback">
                <span>
                  {{ reviews.length ? reviews : "0" }} {{ $t("reviews") }}
                </span>
                <div class="devider"></div>
                <span>
                  {{ questions.length ? questions : "0" }} {{ $t("question") }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="product__card">
          <div class="product__card-price">
            {{ productInfo.current_price }}₸/{{ $t("pc") }}
          </div>
          <div
            class="product__card-oldprice"
            v-if="productInfo.current_price !== productInfo.price"
          >
            {{ productInfo.price }} ₸
          </div>
          <div class="product__card-discount" v-if="productInfo.sale">
            {{ productInfo.sale }} %
          </div>

          <div class="product__card-info">
            <div class="product__card-item">
              <div class="product__card-icon">
                <img src="../assets/icons/location_black.png" alt="" />
              </div>
              <div class="product__card-text">
                г.Алматы
                <!-- Поставщик товара - <span
                                    @click="$router.push('/single-shop/' + product_info.provider.code)">{{product_info.provider.name}}</span> -->
              </div>
            </div>
            <div class="product__card-item">
              <div class="product__card-icon">
                <img src="../assets/icons/mail_black.png" alt="" />
              </div>
              <div class="product__card-text">
                {{ $t("pickup") }} - Уральск, Аксай, Атырау
              </div>
            </div>
            <div class="product__card-item">
              <div class="product__card-icon">
                <img src="../assets/icons/location_black.png" alt="" />
              </div>
              <div class="product__card-text">
                {{ this.$t("delivery") }} - завтра, 21 мая, бесплатно
              </div>
            </div>
            <div class="product__card-additional">
              {{ $t("minimumOrder") }} - {{ productInfo.stock }}
            </div>
          </div>
          <div class="product__card-ask">{{ $t("askSeller") }}</div>
          <div
            class="product__card-button"
            @click="addProductToBasket(productInfo.id)"
            :class="{ 'product__card-delete': isCardInBasket !== false }"
          >
            {{ basket_status }}
          </div>
          <div
            class="product__card-button product__card-button-white"
            @click="$store.state.buyOneClick = true"
          >
            {{ $t("buyClick") }}
          </div>
        </div>
      </div>
      <div class="product__feedbacks" id="navs">
        <div class="product__feedbacks-titles">
          <div
            class="product__feedbacks-title"
            v-for="title in titles"
            :key="title.id"
            @click="clickOnTitle(title.id)"
            v-bind:class="{ active: title.id === count }"
          >
            {{ title.text }}
          </div>
        </div>
        <div class="product__mobile">
          <div class="product__shop" v-show="count === 0">
            <div class="product__shop-item" v-for="item in 5" :key="item.id">
              <div class="product__shop-item-left">
                <div class="product__shop-name">
                  <p>{{ $t("nameShop") }}</p>
                  <StarsRating :rating="3"></StarsRating>
                </div>
                <div class="product__shop-minprice">
                  {{ $t("minimumOrder") }} - <span>96 690 ₸</span>
                </div>
                <div class="product__shop-name">
                  <p>
                    {{ this.$t("delivery") }} - завтра, <span>21 мая</span>,
                    бесплатно
                  </p>
                  <p>{{ $t("pickup") }}- сегодня</p>
                </div>
              </div>
              <div class="product__shop-item-right">
                <button
                  class="product__shop-button"
                  @click="addProductToBasket(item.id)"
                >
                  {{ $t("basket12") }}
                </button>
                <div class="product__shop-price">19 390 ₸/{{ $t("pc") }}</div>
                <div class="product__shop-allcount">232 680 ₸</div>
              </div>
            </div>
          </div>
        </div>
        <div class="product__desctop" v-show="count === 0">
          <div class="product__shop">
            <div class="product__shop-item" v-for="item in 5" :key="item.id">
              <div class="product__shop-name">
                <p>{{ $t("nameShop") }}</p>
                <StarsRating :rating="3"></StarsRating>
              </div>
              <div class="product__shop-minprice">
                {{ $t("minimumOrder") }} - 96 690 ₸
              </div>
              <div class="product__shop-name">
                <p>{{ this.$t("delivery") }} - завтра, 21 мая, бесплатно</p>
                <p>{{ $t("pickup") }}- сегодня</p>
              </div>
              <div class="product__shop-price">19 390 ₸/{{ $t("pc") }}</div>
              <button
                class="product__shop-button"
                @click="addProductToBasket(item.id)"
              >
                {{ $t("basket12") }}
              </button>
              <div class="product__shop-allcount">232 680 ₸</div>
            </div>
          </div>
        </div>
        <div class="product__comments" v-show="count === 1">
          <FeedbackBlock
            :ids="productInfo.id"
            :allFeedback="reviews.length"
            :feedbacks="feedbacks_items"
            :reviews="reviews"
            @open-feedback-modal="openFeedbackModal"
          ></FeedbackBlock>
          <span v-if="reviews.length > 0"  class=fefef>
            <div class="product__comments-sort">
              <p>{{ $t("sortBy") }}</p>
              <select placeholder="Сортировать" v-model="sortByOtziv">
                  <option value="1">По убыванию даты</option>
                  <option value="2">По возрастанию даты</option>
                  <option value="3">Сначало полезные</option>
                  <option value="4">Сначало плохие</option>
              </select>
            </div>
            <div class="product__comments-items otzivWidth" v-if="comments">
              <div
                class="product__comments-item"
                v-for="item in displayedProductsReviews"
                :key="item"
              >
                <div class="product__comments-ratings">
                  <StarsRating
                    :rating="item.rating"
                    :numbers="false"
                  ></StarsRating>
                  <div class="product__comments-name">
                    {{ item.name }}
                  </div>
                  <div class=dateOtziv>
                    {{ item.date }}
                  </div>
                </div>
                <div class="product__comments-wrapper">
                  <div class="product__comments-content">
                    <div class="product__comments-title">
                      {{ $t("feedback") }}
                    </div>
                    <div class="product__comments-text">
                      {{ item.review }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <button class="button button_comments" v-if="reviews.length > 3" @click="showAll = !showAll">
              <p v-if="showAll">Скрыть</p> 
              <p v-else>Показать ещё</p>
            </button>
          </span>
          <span v-else>
            <div class="ansQuest">Нет никаких отзывов.</div>
          </span>

        </div>
        <div class="product__comments" v-show="count === 2">
          <LeaveQuestion
            :ids="productInfo.title"
            :productInfo="productInfo"
            :rating="product_info.raiting"
            :allFeedback="1000"
            :feedbacks="feedbacks_items"
          ></LeaveQuestion>
          <span v-if="comments.length > 0" class=fefef>
            <div class="ansQuest">{{ $t('ansQuest') }} ({{ comments.length }})</div>
              <div class="product__comments-sort">
                <p>{{ $t("sortBy") }}</p>
                <select placeholder="Сортировать" v-model="sortBy">
                  <option value="1">По убыванию даты</option>
                  <option value="2">По возрастанию даты</option>
                  <option value="3">Полезные</option>
                  <option value="4">Плохие</option>
                </select>
              </div>
              <div class="product__comments-items commentQues" v-if="comments.length">
                <div
                  class="product__comments-item"
                  v-for="(item, iddx) in filteredComments"
                  :key="item" 
                > 
                  <!-- <h2>{{ item.id }}</h2> -->
                  <div class="product__comments-ratings">
                    <div class="product__comments-name">
                      {{ item.name }}
                    </div>
                    <div class="product__comments-date">
                      {{ item.question_date }}
                    </div>
                    <div class="product__comments-like">
                      <img :src="require('@/assets/icons/thumb-up.png')" alt="like"/> {{ item.likes }}
                    </div>
                  </div>
                  <div class="product__comments-wrapper">
                    <div class="product__comments-content">
                      <div class="product__comments-title" style="color: #202020; font-weight: 700;">
                        {{ $t("question1") }}
                      </div>
                      <div class="product__comments-text" style="color: #727272; font-weight: 700;">
                        {{ item.question }}
                      </div>
                    </div>
                    <div class="product__comments-content" v-if="item.status_answer == 1">
                      <div class="product__comments-title" style="color: #202020; font-weight: 700;">
                        {{ $t("answer1") }}
                      </div>
                      <div class="product__comments-text" style="color: #727272; font-weight: 700;">
                        <p v-html="item.answer"></p>
                      </div>
                    </div>
                    
                    <div class="product__comments-content helpYou" v-if="!item.is_clicked && item.status_answer == 1 && getUser">
                        {{ $t("helpYou") }}
                        <div class="yesss" @click="likeComment(item.id, 1, iddx)">Да</div>
                        <div class="nooo" @click="dislikeComment(item.id, 1, iddx)">Нет</div>
                    </div>
                    <p v-else-if="item.is_clicked">Ваш ответ принят!</p>
                  </div>
                </div>
              </div>
              <button class="button button_comments" v-if="currentCommentLength > 3" @click="showAll = !showAll">
                <p v-if="showAll">Скрыть</p> 
                <p v-else>Показать ещё</p>
              </button>
          </span>
          <span v-else>
            <div class="ansQuest">Нет никаких коментариев.</div>
          </span>
        </div>

        <template v-if="productInfo.features.length">
          <div
          style="margin-top: 49px;"
            class="product__characteristics"
            v-for="(item, id) in productInfo.features"
            :key="item.id"
            v-show="count === 3"
            :class="{ 'bottom-charac': productInfo.features.length !== id + 1 }"
          >
            <div class="product__characteristics-name">
              {{ item.type }}
            </div>
            <div class="product__about product__about-desc" v-if="item.values">
              <div
                class="product__about-item"
                v-for="elem in item.values"
                :key="elem.id"
              >
                <div class="product__about-info">
                  {{ elem.title }}
                </div>
                <div class="product__about-dots"></div>
                <div class="product__about-info justInfo">
                  {{ elem.value }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="product__feedbacks-feedback" v-show="count === 4">
          <div
            v-html="productInfo.description"
            :class="{ deskShow: !deskShow }"
          ></div>
          <div
            class="mobile"
            v-if="!deskShow"
            @click="deskShow = !deskShow"
            style="
              font-weight: 500;
              font-size: 16px;
              line-height: 17px;
              text-align: center;
              margin-top: 16px;
              text-decoration-line: underline;
              color: #202020;
            "
          >
            Читать больше
          </div>
        </div>
      </div>
      <section class="home__sales xere">
        <div class="home__sales-up">
          <h2 class="home__title-section">
            {{ $t("productTake") }}
          </h2>
          <div class="home__sales-arrows">
            <div class="home__sales-left">
              <img src="../assets/icons/home/arrow_left.png" alt="" />
            </div>
            <div class="home__sales-right">
              <img src="../assets/icons/home/arrow_right.png" alt="" />
            </div>
          </div>
        </div>
        <div class="home__sales-items">
          <div class="home__slider">
            <div class="home__sales-swiper">
              <swiper
                class="home__sales-item"
                :space-between="20"
                :breakpoints="swiper.breakpoints_good_card"
                autoplay="autoplay"
                :watchSlidesProgress="true"
                :allowTouchMove="false"
                :lazy="true"
                :navigation="swiper.salesNavigation"
                :loop="false"
              >
                <swiper-slide
                  v-for="item in productInfo.similars"
                  :key="item.id"
                >
                  <Card :product="item"></Card>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-show="isCommentModal">
      <CommentModal
        @open-comment-modal="openFeedbackModal"
        @send-info="sendInfo"
        :product="productInfo"
      ></CommentModal>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
// props: ['image', 'salePersents', 'price', 'discription', 'count', 'salePrice', 'link'],
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import StarsRating from "./StarsRating.vue";
import Card from "./GoodsCard.vue";
import Modal from "./NotBuyModal.vue";
import FeedbackBlock from "./FeedbackBlock.vue";
import ThumbsSwiper from "./ThumbsSlider.vue";

import CommentModal from "./CommentModal.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import BreadCrumbs from "./UI/BreadCrumbs.vue";
// import ProductDiscription from './ProductDiscription.vue'
// import ProductShow from './ProductShow.vue'

const toaster = createToaster({ position: "top-right" });
import { createToaster } from "@meforma/vue-toaster";
import LeaveQuestion from "./LeaveQuestion.vue";
import BuyOneClick from "./BuyOneClick.vue";
export default {
  data() {
    return {
      showAll: false,
      displayedProductsCount: 3,
      likeValue: 0,
      deskShow: false,
      isCommentModal: false,
      select_value: "better",
      main_imageP: null,
      imagesP: null,
      isActive: false,
      isModal: false,
      currentCommentLength: null,
      currentReviewLength: null,
      isLiked: false,
      count: 0,
      raiting: null,
      sortBy: '1',
      sortByOtziv: '1',
      reviews: [],
      questions: [],
      basket_status: this.$t("addCart"),
      isCardInBasket: null,
      bigPic: require("../assets/img/good.png"),
      productInfo: {},
      product_info: [], // дополнительная информация
      comments: [], // отзывы
      crumbs: [],

      swiper: {
        breakpoints: {
          320: {
            slidesPerView: 5,
          },
          770: {
            slidesPerView: 6,
          },
          991: {
            slidesPerView: 7,
          },
          1200: {
            slidesPerView: 8,
          },
        },
        breakpoint_main: {
          320: {
            slidesPerView: 1,
          },
        },
        breakpoints_good_card: {
          320: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          991: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 5,
          },
        },
        breakpoints_category: {
          320: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          991: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 4,
          },
        },
        brandNavigation: {
          prevEl: ".home__brands-left",
          nextEl: ".home__brands-right",
        },
        shopNavigation: {
          prevEl: ".home__shop-left",
          nextEl: ".home__shop-right",
        },
        salesNavigation: {
          prevEl: ".home__sales-left",
          nextEl: ".home__sales-right",
        },
        categoryNavigation: {
          prevEl: ".home__sales-left1",
          nextEl: ".home__sales-right1",
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination_main: {
          type: "bullets",
          clickable: "true",
          bulletActiveClass: "home__swiper-pagination-bullet-active",
        },
      },
      sorted_comments: [],
      pictures: [
        {
          id: 0,
          img: require("../assets/img/good.png"),
        },
        {
          id: 1,
          img: require("../assets/img/good2.png"),
        },
        {
          id: 2,
          img: require("../assets/img/good3.png"),
        },
        {
          id: 4,
          img: require("../assets/img/good2.png"),
        },
        {
          id: 5,
          img: require("../assets/img/good.png"),
        },
        {
          id: 6,
          img: require("../assets/img/good3.png"),
        },
      ],
      discription: [
        {
          id: 0,
          text: "Max крутящий момент",
          value: "24 Нм",
        },
        {
          id: 1,
          text: "Тип аккумулятора",
          value: "Li-lon",
        },
        {
          id: 2,
          text: "Напряжение аккумулятора",
          value: "10.8 В",
        },
        {
          id: 3,
          text: "Max диаметр сверления (металл)",
          value: "10 мм",
        },
        {
          id: 4,
          text: "Max диаметр сверления (дерево)",
          value: "21 мм",
        },
        {
          id: 5,
          text: "Вес нетто",
          value: "1 кг",
        },
        {
          id: 6,
          text: "Тип",
          value: "аккумуляторный",
        },
        {
          id: 7,
          text: "Тип двигателя",
          value: "щеточный",
        },
      ],
      titles: [
        {
          id: 0,
          text: this.$t("suppliers"),
          open: false,
        },
        {
          id: 1,
          text: this.$t("feedbacks"),
          open: false,
        },
        {
          id: 2,
          text: this.$t("questions"),
          open: false,
        },
        {
          id: 3,
          text: this.$t("specifications"),
          open: false,
        },
        {
          id: 4,
          text: this.$t("description"),
          open: false,
        },
      ],
      characteristics_titles: [
        {
          id: 0,
          text: this.$t("mainSpecifications"),
        },
        {
          id: 1,
          text: this.$t("construction"),
        },
        {
          id: 2,
          text: this.$t("features"),
        },
      ],
      feedbacks_items: [
        {
          id: 0,
          number: 5,
          count: 258,
        },
        {
          id: 1,
          number: 4,
          count: 325,
        },
        {
          id: 2,
          number: 3,
          count: 123,
        },
        {
          id: 3,
          number: 2,
          count: 199,
        },
        {
          id: 4,
          number: 1,
          count: 95,
        },
      ],
    };
  },
  watch: {
    getLiked() {
      this.isLiked = this.getLiked.find((i) =>
        i.product_id == this.productInfo.id 
      );
      if (this.isLiked) {
        this.productInfo.favourId = this.isLiked.id
        this.isLiked = true
      } else {
        this.isLiked = false
      } 
    },
    count() {
      this.deskShow = false;
    },
    productInfo() {
      (this.main_imageP = this.productInfo.main_image),
        (this.imagesP = this.productInfo.images);
      this.isLiked = this.getLiked.find((i) =>
        i.product_id == this.productInfo.id 
      );
      // console.log('asssadad', this.productInfo.id);
      // console.log('asssadad1', this.getLiked);
      if (this.isLiked) {
        this.productInfo.favourId = this.isLiked.id
        this.isLiked = true
      } else {
        this.isLiked = false
      } 

      this.$axios.get('V1/question/product/' + this.productInfo.id)
      .then(res => {
        this.comments = res.data.questions
      })
      .catch(err => {
        // console.log(err);
      });
    },
    $route() {
      this.getProductBySlug();
    },
    select_value(newValue) {
      switch (newValue) {
        // case "all":
        //   this.comments.sort(function (a, b) {
        //     return a.id - b.id;
        //   });
        //   break;
        // case "better":
        //   this.comments.sort(function (a, b) {
        //     return b.rating - a.rating;
        //   });
        //   break;
        // case "worst":
        //   this.comments.sort(function (a, b) {
        //     return a.rating - b.rating;
        //   });
        //   break;
      }
    },
    // selectComments(valueCom) {
    //   switch (valueCom) {
    //     case "0": 
    //       this.comments.sort(function (a, b) {
    //         return a.id - b.id;
    //       })
    //     case "1": 
    //       this.comments.sort(function (a, b) {
    //         return a.id - b.id;
    //       })
    //     case "2": 
    //       this.comments.sort(function (a, b) {
    //         return a.id - b.id;
    //       })
        
    //   }
    // }
  },
  async created() {
    window.scrollTo(0, 0);
    await this.setProduct();
    await this.getSingleProduct(this.$route.params.id);
    await this.getProductBySlug();
  },
  mounted() {

    

    this.isCardInBasket = this.$store.state.card.some((i) =>
      i.id === this.productInfo.id ? true : false
    );
    this.basket_status = !this.isCardInBasket
      ? this.$t("addCart")
      : this.$t("deleteCart");

    (this.main_imageP = this.productInfo.main_image),
      (this.imagesP = this.productInfo.images);
  },
  methods: {    

    likeComment(value, number, iddx) {
      this.likeValue = number;

      const likesQ = {
        likes: this.likeValue,
        is_clicked: 1,
      }
      this.$axios.post('V1/question/like/' + value, likesQ, {
        headers: {
          Authorization: "Bearer ".concat(
            localStorage.getItem("access_token")
          ),
        },
      })
      .then(res => {
        this.comments[iddx].is_clicked = true
      })
      .catch(err => {
        // console.log(err);
      });
    },
    dislikeComment(value, number, iddx) {
      this.likeValue = number;

      const likesQ = {
        dislikes: this.likeValue,
        is_clicked: 1
      }
      this.$axios.post('V1/question/dislike/' + value, likesQ, {
        headers: {
          Authorization: "Bearer ".concat(
            localStorage.getItem("access_token")
          ),
        },
      })
      .then(res => {
        this.comments[iddx].is_clicked = true
      })
      .catch(err => {
        // console.log(err);
      });
    },

    ...mapMutations(["SET_ALL_FAVOURITES"]),
    scrollTo(link) {
      const offsetTop = document.getElementById(link).offsetTop;
      document.getElementById(link).scrollIntoView({
        top: offsetTop,
        behavior: "smooth",
      });
    },
    notWishlist() {
      toaster.error(this.$t('notAddWishlist'));
    },
    sendInfo(data) {
      // console.log("feedbakc", data);

      this.POST_FEEDBACK({
        id: Date.now(),
        user: "New User " + Date.now(),
        time: "26.09.2022",
        rating: data.rating,
        advantages: data.text,
      });
      this.clickOnTitle(1);
      this.getProductBySlug()
      // console.log("новый отзыв это", this.$store.state.allFeedbacks);
    },
    openFeedbackModal() {
      this.isCommentModal = !this.isCommentModal;
      // console.log("openFeedbackModal 123");
    },
    ...mapActions([
      "POST_FEEDBACK",
      "GET_SINGLE_PRODUCT_INFO",
      "GET_ALL_PRODUCTS_INFO",
      "GET_ALL_FEEDBACKS",
      "ADD_LIKED_PRODUCT",
      "DELETE_CARD_ITEM",
      "ADD_PRODUCT_TO_BASKET",
      "DELETE_BASKET_ITEM",
    ]),
    async getSingleProduct(id) {
      let info = this.$store.state.allProductInfo;
      for (let i = 0; i < info.length; i++) {
        if (info[i].id == id) {
          this.product_info = info[i];
        }
      }
    },
    async getProductBySlug() {
      await this.$axios
        .get(`V1/product-by-slug`, {
          params: {
            slug: this.$route.params.id,
          },
        })
        .then((res) => {
          this.productInfo = res.data.data;
          this.raiting = res.data.raiting;
          this.reviews = res.data.reviews;
        })
        .catch(() => { });
      await this.$axios.get(`V1/categories?id=${this.productInfo.category_id}`).then(res => {
        this.crumbs.push({ title: res.data.data[0].title, slug: res.data.data[0].slug })
        this.crumbs.push({ title: res.data.data[0].parent[0].title, slug: res.data.data[0].parent[0].slug })
      }).catch(err => console.log('err'))
    },
    addProductToFavorite(id) {
      if (this.isLiked === false) {
        this.$axios.post('V1/add-favourite?lang=ru', {
          product_id: id
        }, {
            headers: {
                Authorization: "Bearer ".concat(
                    localStorage.getItem("access_token")
                ),
            },
        })
        .then(res => {
          // console.log('res.data.data',res.data.data)
          this.ADD_LIKED_PRODUCT(res.data.data);
          this.productInfo.favourId = res.data.data.id
          this.isLiked = true
          
    this.$axios.get('V1/get-favourites', {
          headers: {
              Authorization: "Bearer ".concat(
                  localStorage.getItem("access_token")
              ),
          },
        })
        .then(res => {
          this.SET_ALL_FAVOURITES(res.data.data)
        })
        // .catch(err => console.log(err))
        })
        // .catch(err => console.log(err))
      } else {
        this.$axios.delete('V1/delete-favourite?lang=ru', {
          params: {
            product_id: this.productInfo.id 
          },
          headers: {
              Authorization: "Bearer ".concat(
                  localStorage.getItem("access_token")
              ),
          },
        })
        .then(res => {
          this.DELETE_CARD_ITEM(this.productInfo.id);
        })
        // .catch(err => console.log(err))
      }
    },
    checkCardStatus() {
      if (this.isCardInBasket == true) {
        this.basket_status = this.$t("addCart");
        this.DELETE_BASKET_ITEM(this.productInfo.id);
        this.$emit("deleteBasketCard", this.productInfo.id);
      } else {
        this.basket_status = this.$t("deleteCart");
        this.$emit("addBasketCard", this.productInfo);
        this.ADD_PRODUCT_TO_BASKET(this.productInfo);
      }
      this.isCardInBasket != this.isCardInBasket;
    },
    addProductToBasket(id) {
      this.checkCardStatus(id);
      this.isCardInBasket = !this.isCardInBasket;
    },
    setProduct() {
      this.product = this.$store.state.singleCard;
      // this.$set(this, 'product_info', this.GET_SINGLE_PRODUCT_INFO)
    },
    sortedItems() {
      switch (this.sortBy) {
        case '1':
          return this.comments.sort((a, b) => {
            return new Date(b.question_date.split('.').reverse().join('-')) - new Date(a.question_date.split('.').reverse().join('-'))
          })
        case '2':
          return this.comments.sort((a, b) => {
            const dateA = new Date(a.question_date.split('.').reverse().join('-'));
            const dateB = new Date(b.question_date.split('.').reverse().join('-'));

            return dateA - dateB;
          });
        case '3':
          const filteredComments = this.comments.filter(comment => comment.likes !== null);
          return filteredComments.sort((a, b) => new Date(a.date) - new Date(b.date));
        case '4':
          const filteredCommentsDislike = this.comments.filter(comment => comment.likes == null);
          return filteredCommentsDislike.sort((a, b) => new Date(a.date) - new Date(b.date));
        default:
          return this.comments;
      }
    },

    sortedItemsOtivz() {
      switch (this.sortByOtziv) {
        case '1':
            return this.reviews.sort((a, b) => {
            return new Date(b.date.split('.').reverse().join('-')) - new Date(a.date.split('.').reverse().join('-'))
          })
        case '2':
          return this.reviews.sort((a, b) => {
            const dateA = new Date(a.date.split('.').reverse().join('-'));
            const dateB = new Date(b.date.split('.').reverse().join('-'));

            return dateA - dateB;
          });
        case '3':
            return this.reviews.sort((a, b) => b.rating - a.rating);
        case '4':
            return this.reviews.sort((a, b) => a.rating - b.rating);
        default:
          return this.reviews;
      }
    },


    async clickOnTitle(id) {
      this.count = id;
      this.isActive = !this.isActive;
      if (id == 1) {
        await this.GET_ALL_FEEDBACKS();
        // this.comments = this.STATE.allFeedbacks;
      } else {
      }
    },
  },

  computed: {
    ...mapGetters(["STATE", "getIsAuth", "getLiked", "getUser"]),
    mainImg() {
      return this.main_imageP;
    },
    displayedProductsReviews() {
      const sortedItemsOtziv = this.sortedItemsOtivz();
      this.currentCommentLength = sortedItemsOtziv.length
      if (this.getUser?.questions_clicked?.length) {
      this.reviews.forEach((item, idx) => {
        this.reviews[idx].is_clicked = JSON.parse(this.getUser.questions_clicked).some(elem => elem.question_id == item.id)
      })
      }
      return this.showAll ? this.reviews : this.reviews.slice(0, this.displayedProductsCount);
    },

    filteredComments() {
      const sortedItems = this.sortedItems();
      this.currentCommentLength = sortedItems.length
      if (this.getUser?.questions_clicked?.length) {
        sortedItems.forEach((item, idx) => {
          sortedItems[idx].is_clicked = JSON.parse(this.getUser.questions_clicked).some(elem => elem.question_id == item.id);
        });
      }

      return this.showAll ? sortedItems : sortedItems.slice(0, this.displayedProductsCount);
    },

    imagesPP() {
      return this.imagesP;
    },
  },
  props: [
    "countOfQuestions",
    "countOfFeedbacks",
    "starRating",
    "productCode",
    "fullName",
  ],
  components: {
    StarsRating,
    Swiper,
    SwiperSlide,
    Card,
    Modal,
    FeedbackBlock,
    ThumbsSwiper,
    CommentModal,
    BreadCrumbs,
    LeaveQuestion,
    BuyOneClick
},
};
</script>

<style lang="scss" scoped>
.contentProduct {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  z-index: 10;
}
// .desctop {
//   flex-grow: 1;
//   padding: 0 20px;
// }
.product__desctop {
  display: block;

  @media (max-width: 991px) {
    display: none;
  }
}

.product__mobile {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

.product__info {
  &-icon {
    height: 16px;
    width: 16px;

    &-like {
      background-image: url("../assets/icons/card/like.svg");
    }
  }

  &_empty {
    background-image: url("../assets/icons/card/like_filled.svg");
  }
}

.deskShow {
  @media (max-width: 991px) {
    height: 70px;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.ansQuest {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #202020;
  padding: 0px 0 48px 0;
}

.helpYou {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #828282;
  div {    
    padding: 8px 16px;
    background: white;
    border-radius: 5px;       
    color: #202020;
    border: 1px solid #202020;
    &:hover {
    background: #202020;
    color: white; 
      cursor: pointer;
    }
  }
  .yesss {
    margin: 0 10px 0 24px ;
    background: #202020;
    border-radius: 5px;  
    color: white; 
    transition: all 0.5s ease;
    &:hover {
      background: white;
      color: #202020;
      border: 1px solid #202020;
      cursor: pointer;
    }
  }
  .nooo {
    background: #202020;
    border-radius: 5px;  
    color: white; 
    transition: all 0.5s ease;
    &:hover {
      background: white;
      color: #202020;
      border: 1px solid #202020;
      cursor: pointer;
    }
  }
}

.prodTop {
  width: 27.28%;
}
.home__sales-item {
  padding: 12px;
}
.activeLikeComment {
  filter: brightness(0) saturate(100%)
}
.commentQues {
  width: 80%;
}

.xere {
  position: relative;
  padding: 0px 0 60px 0;
  background-color: #fff;
}
.fefef {
  width: 100%;
}
.otzivWidth {
  width: 80%;
}

.dateOtziv {
  font-weight: 400;
    font-size: 15px;
    color: #c2c2c2;
}
</style>