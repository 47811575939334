import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import i18n from "./i18n";
import store from './store'
import VueAwesomePaginate from "vue-awesome-paginate";
import VueHead from 'vue-head'
import axios from 'axios'
import '@/assets/style/main.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { createToaster } from "@meforma/vue-toaster";
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import vClickOutside from "click-outside-vue3"
import "vue3-treeselect/dist/vue3-treeselect.css";

import scrollDirectives from './directives/scrollDirectives';



const axiosInstance = axios.create({
  baseURL: 'https://admin-ges.test-vip.kz/api/',
  params: {
    lang: store.getters.getLang
  }
})

axios.defaults.baseURL = 'https://admin-ges.test-vip.kz/api/';
axios.defaults.params = {
  lang: store.getters.getLang
};


const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(vClickOutside)
  .use(VueAwesomePaginate)
  .directive('scroll', scrollDirectives)
  .directive('outside', clickOutSide)
app.config.silent = true
app.config.globalProperties.$axios = { ...axiosInstance }
app.config.globalProperties.$toaster = createToaster({ position: "top-right" });
app.config.globalProperties.$cdn = 'https://cdn-ges.test-vip.kz/'
app.mount('#app')
