<template>
  <div class="contact">
    <div class="content">
      <!-- <BreadCrumbs :items="[[$t('contacts'), '/contact']]" /> -->
      
      <div class="bread"> 
          <router-link to="/" style="color: black"><p>{{ $t('main') }}</p></router-link>
         <div style="opacity: 0.3;">•</div>
         <p style="opacity: 0.7;">{{ $t('contacts') }}</p>
      </div>

      <div class="contact__title">{{ contacts1.data.title }}</div>
      <div class="contact__wrapper">
        <div class="contact__info">
          <div class="contact__time">{{ contacts1.data.description }}</div>
          <div class="contact__items">
            <div class="contact__item">
              <div class="contact__icon">
                <img src="../assets/icons/location_black.png" alt="" />
              </div>
              <span v-html="contacts1.data.main_address"></span>
            </div>
            <div class="contact__item" style="align-items: flex-start;">
              <div class="contact__icon">
                <img src="../assets/icons/phone_black.png" alt="" />
              </div>
              <!-- <div style="display: flex; gap: 8px;">
                <a v-for="(item, index) in nubmerTel" :key="item.phone" :href="'tel:' + item.phone" style="color: #202020">
                  {{ item.phone }}
                  <span v-if="index !== nubmerTel.length - 1">,</span>
                </a>
              </div> -->
              <div style="display: flex; flex-direction: column; gap: 8px;">
                <p>{{ $t('Розничные продажи') }}</p>
                <a :href="'tel:' + contacts1.data.retail_sale" style="color: #202020;">{{ contacts1.data.retail_sale }}</a>
                <p>{{ $t('Оптовые продажи') }}</p>
                <a :href="'tel:' + contacts1.data.wholesale " style="color: #202020;">{{ contacts1.data.wholesale }}</a>
              </div>
            </div>
            <div class="contact__item">
              <div class="contact__icon">
                <img src="../assets/icons/mail_black.png" alt="" />
              </div>
              <span>{{ contacts1.data.email }}</span>
            </div>
          </div>
          <!-- <div class="contact__text">Следите за акциями в соцсетях!</div> -->
          <div class="contact__social">
            <a :href="contacts1.data.instagram" class="contact__media" target="_blank">
              <img src="../assets/icons/media/inst.svg" alt="" />
            </a>
            <a :href="contacts1.data.facebook" class="contact__media" target="_blank">
              <img src="../assets/icons/media/fb.svg" alt="" />
            </a>
            <a :href="contacts1.data.vk" class="contact__media" target="_blank">
              <img src="../assets/icons/media/vk.svg" alt="" />
            </a>
          </div>
        </div>
        <div class="contact__map">
          <iframe
            :src="contacts1.data.map + '&embed=1'"
            width="100%"
            height="100%"
            style="border: 0; margin-top: -64px;"
            allowfullscreen="false"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/UI/BreadCrumbs.vue";

export default {
  components: { BreadCrumbs },
  data() {
    return {
      contacts1: {},
      nubmerTel: []
    }
  },
  created() {    
    this.$axios.get('get-contacts?lang=ru')
      .then(res => {
        this.contacts1 = res.data;
        this.nubmerTel = res.data.cities
      })
      .catch(err => {
        // console.log(err);
      })
  },
};
</script>

<style lang="scss">
.contact__item {
  align-items: start !important;
}
.bread {
  display: flex;
  gap: 5px;
}
</style>