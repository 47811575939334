<template>
    <div class="news__card">
    <router-link :to="'/news/' + link">
       <div class="news__card__img">
        <img :src="background" alt="Background" />
        <div class="news__card__analytics" v-if="category">
          {{ category }}
        </div>
      </div>
    </router-link>
    <div class="news__card__content">
      <div class="news__card__info">
        <div class="news__card__item">
          <div class="news__card__item-img">
            <img src="../assets/icons/news_icon_time.svg" alt="" />
          </div>
          <div class="news__card__item-text">
            {{ time }}
          </div>
        </div>
        <div class="news__card__item">
          <div class="news__card__item-img">
            <img src="../assets/icons/news_icon_view.svg" alt="" />
          </div>
          <div class="news__card__item-text">
            {{ views }}
          </div>
        </div>
      </div>
      <div class="news__card__discription">
        <router-link :to="'/news/' + link" class="news__card__discription-title" style="color: black;">{{ title }}</router-link>
        <div class="news__card__discription-text" v-html="discription" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: [
    "background",
    "time",
    "views",
    "title",
    "discription",
    "link",
    "category",
  ],
};
</script>

<style lang="scss">
.news__card {
  position: relative;
  width: 381px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);

  &__analytics {
    position: absolute;
    padding: 0 10px;
    left: 12px;
    top: 228px;
    min-width: 73px;
    height: 40px;
    background: #508ecb;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    position: relative;
    width: 381px;
    max-height: 280px;
    height: 280px;
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: 0 16px 24px;
  }

  &__info {
    margin-top: 26px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 20px;
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-self: center;

    gap: 0 8px;

    &-img {
      width: 15px;
    }

    &-text {
      font-weight: 500;
      font-size: 15px;
      color: #626262;
    }
  }

  &__discription {
    margin-top: 13px;
    color: #2c3131;

    &-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }

    &-text {
      // margin-top: 5px;
      // font-weight: 400;
      // font-size: 16px;
      // line-height: 21px;
      // overflow: hidden;
      // display: -webkit-box;
      // -webkit-line-clamp: 3;
      // -webkit-box-orient: vertical;

      
        
         font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.2px) * 0.5385))), 16px) !important;
        overflow: hidden;
        display: -webkit-box;
        color: #727272 !important;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
        
    }
  }

  &__arrow {
    width: 18px;
    position: absolute;
    right: 19px;
    bottom: 17px;
  }
}

@media (max-width: 1919.98px) {
  .news__card {
    width: 100%;

    &__analytics {
      top: 73%;
    }

    &__img {
      width: 100%;
      height: 100%;
    }

    &__content {
      margin: 0 16px 44px 0;
    }

    &__info {
      margin-top: 26px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0 20px;
    }

    &__item {
      display: flex;
      justify-content: flex-start;
      align-self: center;
      gap: 0 8px;

      &-img {
        width: 15px;
      }

      &-text {
        font-weight: 500;
        font-size: 15px;
        color: #626262;
      }
    }

    &__discription {
      margin-top: 13px;
      color: #2c3131;

      &-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }

      &-text {
        margin-top: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
      }
    }

    &__arrow {
      width: 18px;
      position: absolute;
      right: 19px;
      bottom: 17px;
    }
  }
}

@media (max-width: 1400.98px) {
  .news__card {
    &__img {
      max-height: 200px;
    }
    &__content {
      padding: 0;
      margin: 0 16px 44px 16px;
    }
  }
}

@media (max-width: 576.98px) {
  .news__card {
    width: 100%;
    max-height: 360px;

    &__analytics {
      position: absolute;
      padding: 0 8px;
      height: auto;
      min-width: auto;
      left: 5px;
      top: 75%;
      min-height: 22px;
      max-width: 126px;
      font-size: 10px;
      text-align: center;
    }

    &__img {
      width: 100%;
      height: 100px;
    }

    &__content {
      padding-top: 8px;
      margin: 0 12px 24px 12px;
    }

    &__info {
      display: none;
    }

    &__discription {
      margin-top: 0px;

      &-title {
        font-size: 10px;
        line-height: 12px;
      }

      &-text {
        // margin-top: 5px;
        // font-size: 9px;
        // line-height: 11px;
        // -ms-text-overflow: ellipsis;
        // -o-text-overflow: ellipsis;
        // text-overflow: ellipsis;  
        // overflow: hidden;
        // -ms-line-clamp: 4;
        // -webkit-line-clamp: 4;
        // line-clamp: 4;
        // display: -webkit-box;
        // display: -moz-box;
        // word-wrap: break-word;
        // -webkit-box-orient: vertical;
        // box-orient: vertical;
        
        max-height: 33px;
        font-size: 9px;
        overflow: hidden;
        line-height: 11px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        
      }
    }

    &__arrow {
      display: none;
    }
  }
}
</style>