<template>
    <div class="app">
        <MainHeader></MainHeader>
        <div class="divdiv">
            <slot />
        </div>
        <MainFooter></MainFooter>
    </div>
</template>

<script>
import MainHeader from '../../components/Header.vue'
import MainFooter from '../../components/Footer.vue'
export default {
    components: {
        MainHeader, MainFooter
    }
};
</script>
 
<style lang="scss">
.divdiv {
    @media screen and (max-width: 992px) {
        padding-bottom: 55px !important;
    }
}
</style>