<template>
  <div class="authorization block animated fadeIn" v-scroll v-if="$store.state.openPasswordModal == true" @click="$store.state.openPasswordModal = false">
    <div class="content" @click="$store.state.openPasswordModal = false">
      <div class="authorization-enter" @click.stop>
        <img src="@/assets/icons/close.svg" class="closeIcon" @click="$store.state.openPasswordModal = false"/>
        <div class="authorization-enter__wrapper">
          <h2 class="authorization__title">{{ $t("forgotPassword") }}</h2>

          <div class="authorization-enter__items">
            <div class="authorization-enter__item">
              <div class="authorization-enter__name">E-mail</div>
              <div class="authorization-enter__input">
                <input type="email" v-model="email" @keyup.enter="sendCode"/>
                <span v-if="v$.email.$error"
                  >Введите корректый формат email</span
                >
              </div>
            </div>
            <div class="authorization-enter__item" v-if="showCodeForm">
              <div class="authorization-enter__name">Пароль</div>
              <div class="authorization-enter__input">
                <input type="text" v-model="code" />
                <span v-if="v$.code.$error">Это обязательное поле</span>
              </div>
            </div>
          </div>
          <button
            v-if="!showCodeForm"
            class="button button_auth"
            @click="sendCode"
          >
            {{ $t("getCode") }}
          </button>
          <button v-else class="button button_auth" @click="checkCode">
            {{ $t("sendCode") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
// import { IMaskDirective } from 'vue-imask';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });
import axios from "axios";

import scrollDirectives from "@/directives/scrollDirectives";
export default {
  directives: {
    scroll: scrollDirectives
  },
  data() {
    return {
      v$: useVuelidate(),
      email: "",
      code: "",
      showCodeForm: false,
      checked: false,
      showPass: true,
    };
  },
  computed: {
    formValid() {
      return this.v$.$invalid;
    },
  },
  validations() {
    // const mustBeCorrectPhoneNumber = (value) => value.include('+7')
    return {
      email: { required, email },
      code: { required },
    };
  },

  methods: {
    sendCode() {
      axios
        .post("V1/password-reset", {
          email: this.email,
        })
        .then(() => {
          this.showCodeForm = true;
        })
        .catch((error) => {
          toaster.error('Ошибка!');
        });
    },
    checkCode() {
      axios
        .post("V1/check-code", {
          email: this.email,
          code: this.code,
        })
        .then((response) => {
          localStorage.setItem("access_token", response.data.access_token);
          this.$router.push("/change-password");
        })
        .catch((error) => {
          toaster.error(error);
        });
    },
  },
};
</script>


<style lang="scss" scoped>

.animated {
  animation-duration: 1s; /* длительность анимации */
  animation-fill-mode: both; /* сохраняем состояние после анимации */
}
.fadeIn {
  animation-name: fadeIn; /* имя анимации */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.authorization-enter {
  position: relative;
}

.closeIcon {
  cursor: pointer;
  width: auto;
  position: absolute;
  right: 16px;
  top: 16px;
  transition: all 0.7s ease;
  &:hover {
    rotate: 180deg;
    transform: scale(1.1);
  }
}
</style>