<template>
  <div class="range">
    <div class="range__slider">
      <div class="range__slider-progress"></div>
    </div>
    <div class="range-input">
      <input
        type="range"
        class="range-min"
        :min="min"
        :max="max"
        v-model="currentMin"
        :step="step"
        @input="rangeSwipe($event)"
        @mouseup="this.$emit('updatemin', this.currentMin)"
      />
      <input
        type="range"
        class="range-max"
        :min="min"
        :max="max"
        v-model="currentMax"
        :step="step"
        @input="rangeSwipe($event)"
        @mouseup="this.$emit('updatemax', this.currentMax)"
      />
    </div>
    <div class="range__price">
      <div class="range__price-field">

        <p>от</p>
        <input
          type="number"
          class="input-min"
          :max="currentMax - step"
          :value="currentMin"
          @input="priceRange($event)"
        />
      </div>

      <div class="range__price-field">
        <p>до</p>
        
        <input
          type="number"
          class="input-max"
          :min="currentMin + step"
          :value="currentMax"
          @input="priceRange($event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DoubleRangeSlider",
  data: () => ({
    priceGap: 50,
    currentMin: 0,
    currentMax: 100,
  }),
  props: {
    min: {
      type: Number,
      required: true,
      default: 0,
    },
    max: {
      type: Number,
      required: true,
      default: 100,
    },
    step: {
      type: Number,
      required: true,
      default: 1,
    },
    range: {
      type: Array,
      required: true,
      default: () => [0, 100],
    }
  },
  mounted() {
    this.currentMin = this.range[0];
    this.currentMax = this.range[1];
    this.priceGap = this.step;

    const range = document.querySelector(
      ".range__slider .range__slider-progress"
    );

    range.style.left = (this.currentMin / this.max) * 100 + "%";
    range.style.right = 100 - (this.currentMax / this.max) * 100 + "%";
  },

  // watch: {
  //   currentMin(newVal, oldVal) {
  //     if (newVal > this.currentMax) {
  //       this.currentMax = newVal;
  //     }
  //   },
  //   currentMax(newVal, oldVal) {
  //     if (newVal < this.currentMin) {
  //       this.currentMin = newVal;
  //     }
  //   },
  // },

  methods: {
    rangeSwipe(e) {
      const range = document.querySelector(
        ".range__slider .range__slider-progress"
      );
      let minVal = parseInt(this.currentMin),
        maxVal = parseInt(this.currentMax);
      // console.log(range);

      if (maxVal - minVal < this.priceGap) {
        if (e.target.className === "range-min") {
          this.currentMin = maxVal - parseInt(this.priceGap);
        } else {
          this.currentMax = minVal + parseInt(this.priceGap);
        }
      } else {
        this.currentMin = minVal;
        this.currentMax = maxVal;
        range.style.left = (this.currentMin / this.max) * 100 + "%";
        range.style.right = 100 - (this.currentMax / this.max) * 100 + "%";
      }
    },
    priceRange(e) {
      const priceInput = document.querySelectorAll(".range__price input")
      const range = document.querySelector(
        ".range__slider .range__slider-progress"
      );
        let minPrice = parseInt(priceInput[0].value),
        maxPrice = parseInt(priceInput[1].value);
      //  console.log(maxPrice - minPrice >= this.priceGap);
      if (
        maxPrice - minPrice >= this.priceGap &&
        maxPrice <= this.max
      ) {
        if (e.target.className === "input-min") {
          this.currentMin = minPrice
          range.style.left = (minPrice / this.max) * 100 + "%";
          this.$emit('updatemin', this.currentMin)
        } else {
          this.currentMax = maxPrice
          range.style.right =
            100 - (maxPrice / this.max) * 100 + "%";
          this.$emit('updatemax', this.currentMax)
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.range {
  margin-top: 24px;
  &__price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 30px 0 35px;
    &-field {
      border: 1px solid #C2C2C2;
      border-radius: 5px;
      display: flex;
      width: 100%;
      height: 45px;
      padding-left: 12px;
      align-items: center;
      p {
          font-size: min(max(10px, calc(0.625rem + ((1vw - 3.2px) * 0.1538))), 12px);
          color: #727272; 
      }
      input {
        border: none;
        background: none;
        outline: none;
        width: 100%;
        box-sizing: border-box;
        -moz-appearance: textfield;
      }
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type="range"]::-webkit-slider-runnable-track {
        background-color: transparent;
        border-radius: 5px; 
      }
    }
    &-separator {
      width: 130px;
      display: flex;
      font-size: 19px;
      align-items: center;
      justify-content: center;
    }
  }
  &__slider {
    height: 5px;
    position: relative;
    background: #ddd;
    border-radius: 5px;
    &-progress {
      height: 100%;
      left: 25%;
      right: 25%;
      position: absolute;
      border-radius: 5px;
      background: #282828;
    }
  }
  &-input {
    position: relative;
    input {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 5px;
      top: -7px;
      left: -2px;
      background: none;
      pointer-events: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    input[type="range"]::-webkit-slider-thumb {
      height: 17px;
      width: 17px;
      border-radius: 50%;
      background: #282828;
      pointer-events: auto;
      -webkit-appearance: none;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    }
    input[type="range"]::-moz-range-thumb {
      height: 17px;
      width: 17px;
      border: none;
      border-radius: 50%;
      background: #282828;
      pointer-events: auto;
      -moz-appearance: none;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
